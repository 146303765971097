import React from 'react';

import PageLayout from '../layout/PageLayout2';



function Analytics  ()  {  

  const WrappedComponent = PageLayout; 

  return (
    <div>
      <WrappedComponent>

        <div className='AnalyticsPage'>
            <h3 className="community-header">Analytics</h3>
        </div>      

      </WrappedComponent>
    </div>
  );
};

export default Analytics;
