import React, { useEffect, useState } from "react";
import "../styles/Conference.css";

import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebase";

import { useSelector } from "react-redux";
import { selectUser } from "../features/userSlice";

import logo from "../assets/images/all-users/conforencelogo1.svg";
import logo2 from "../assets/images/all-users/logo2.png";

import Spinner from "../components/common/Spinner";
import ConferenceItem from "../components/conference/ConferenceItem";

import PageLayout from "../layout/PageLayout1";
import ConferencePopup from "../components/conference/ConferencePopup";

//QR code
import QRCode from "qrcode.react";

function Conference() {
  const WrappedComponent = PageLayout;
  const user = useSelector(selectUser);

  const [conferences, setConferences] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // save user role
  const [mainRole, setMainRole] = useState(null);

  useEffect(() => {
    // 1. Get the user's email from your authentication method.
    const userEmail = user.email;
    // 2. Check if the user's email exists in the "Participants" collection.
    const participantsRef = collection(db, "participant");
    const emailQuery = query(participantsRef, where("email", "==", userEmail));

    getDocs(emailQuery)
      .then((querySnapshot) => {
        if (!querySnapshot.empty) {
          // The user's email exists in the "Participants" collection.
          // 3. Fetch and display the conferences from the "Conferences" collection.
          const conferencesRef = collection(db, "Conferences");
          const participantData = querySnapshot.docs[0].data();
          setMainRole(participantData.main_role);

          getDocs(conferencesRef)
            .then((conferenceSnapshot) => {
              const conferenceData = [];
              conferenceSnapshot.forEach((doc) => {
                const data = doc.data();
                conferenceData.push({
                  id: doc.id,
                  name: data.name,
                  description: data.description,
                  location: data.location,
                  dates: data.dates,
                });
              });
              setConferences(conferenceData);
              setIsLoading(false);
            })
            .catch((error) => {
              console.error("Error fetching conferences: ", error);
              setIsLoading(false);
            });
        } else {
          // The user's email does not exist in the "Participants" collection.
          setIsLoading(false);


        }
      })
      .catch((error) => {
        console.error(
          "Error checking email in Participants collection: ",
          error
        );
        setIsLoading(false);
      });
  }, []);

  return (
    <div>
      <WrappedComponent>
        <div className="conferencePage">
          <div className="__conferences">
            {/* Current Conferences */}
            <div className="confo current-conferences">
              <h3>Current Conferences</h3>

              {isLoading ? (
                <Spinner></Spinner>
              ) : conferences.length > 0 ? (
                <div className="items">
                  {conferences.map((conference) => (
                    <ConferenceItem
                      key={conference.id}
                      conference={conference}
                      mainRole={mainRole}
                    />
                  ))}
                </div>
              ) : (
                <h3>You have Not any conferences</h3>
              )}
            </div>

            {/* Past Conferences */}
            <div className="confo past-conferences">
              <h3>Scan to Download Our App</h3>

              <div className="QR_div" style={{marginLeft: "50%", marginTop: "10%"}}>
                <QRCode
                  value="QR1111"
                  bgColor="#004E89"
                  fgColor="rgba(204, 204, 204, 0.87)"
                  size={200}
                />
              </div>

              {/* <div className="--past-conferences">
                <div className="past-conferences--info">
                  <img src={logo} alt="" />
                  <p>ICTER 2022</p>
                </div>

                <div className="past-conferences--info">
                  <img src={logo2} alt="" />
                  <p>ICACE 2021</p>
                </div>


                <div className="past-conferences--info">
                  <img src={logo} alt="" />
                  <p>ICTER 2021</p>
                </div>
              </div> */}
            </div>
          </div>

          {/* Conference Page Footer */}
          <div className="--page-footer">
            <h6>Note :</h6>
            <p>
              If you have obtained your participation ticket and cannot view the
              relevant conference listed here, kindly reach out to the
              organizing committee for assistance in gaining access.
            </p>
          </div>
        </div>
      </WrappedComponent>
    </div>
  );
}

export default Conference;
