import React, { useState } from "react";

import logo from "../../assets/images/all-users/conforencelogo1.svg";
import location from "../../assets/images/all-users/location.svg";
import mic from "../../assets/images/all-users/mic.svg";
import clock from "../../assets/images/all-users/clock.svg";
import calendar from "../../assets/images/all-users/calendar.svg";

import "../../styles/ConferenceItem.css";

function Rating({ rating, onRatingChange }) {
  const maxRating = 5;
  const stars = [];

  for (let i = 1; i <= maxRating; i++) {
    stars.push(
      <span
        key={i}
        className={`star ${i <= rating ? "active" : ""}`}
        onClick={() => onRatingChange(i)}
      >
        &#9733; {/* Unicode star character */}
      </span>
    );
  }

  return <div className="rating">{stars}</div>;
}

function SessionDetails({}) {
  const [rating, setRating] = useState(0);

  const handleRatingChange = (newRating) => {
    setRating(newRating);
  };
  return (
    <div>
    <div className="session__detail__card">
      <div className="session-detail-card--header">
        <img src={logo} alt="" />
      </div>
      <div className="session-details-card">
        <h5>Gamification for Learning</h5>
        <div className="session-info--detail-card">
          <img src={mic} alt="" />
          <p>Mr. Chirath Perera (Author)</p>
        </div>
        <div className="session-info--detail-card">
          <img src={location} alt="" />
          <p>S202 Hall</p>
        </div>
        <div className="session-info--detail-card">
          <img src={calendar} alt="" />
          <p>2021.05.22</p>
        </div>
        <div className="session-info--detail-card">
          <img src={clock} alt="" />
          <p>8.00am - 10.00am</p>
        </div>
      </div>
      <Rating rating={rating} onRatingChange={handleRatingChange} />
    </div>
    <div className="session-info--detail-description">
          <h5>
            Gamification is the process of transforming the typical classroom environment by using gaming themes. The purpose of this is to 
            increase engagement through the use of competition (both individual and class-wide), creativity, student-led learning, and immediate feedback.
          </h5>
        </div>
    </div>
  );
}

export default SessionDetails;
