import { React, useState } from 'react';
import '../styles/Community.css';

import { useLocation, useNavigate, Outlet , Link  } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import SearchBox from "../components/community/SearchBox";

import PageLayout from '../layout/PageLayout2';

function Community() {

    const WrappedComponent = PageLayout;
    const location = useLocation(); // Move this line to the top of the component

    

    

    const [selectedTab, setSelectedTab] = useState(null);

    const [activeNavItem, setActiveNavItem] = useState('');

    const handleNavLinkClick = (topbar__item) => {
        setActiveNavItem(topbar__item);
    };

    return (
        <div>
            <WrappedComponent>

                <div className='CommunityPage'>
                    <div className='CommunityPage__header'>
                        <h3 className="community__header">Community</h3>
                        <SearchBox />
                    </div>

                    {/* <TopBar selectedTab={selectedTab} setSelectedTab={setSelectedTab} /> */}
                    <div className='communityPage-topbar'>

                    <Link to="/conference/community/oc_members" className={`topbar__item ${
                        location.pathname === '/conference/community/oc_members' ? 'active_topbar__item' : ''
                        }`}
                        onClick={() => handleNavLinkClick('oc_members')}
                    >
                        OC Members 
                    </Link>
                    <Link to="/conference/community/speakers" className={`topbar__item ${
                        location.pathname === '/conference/community/speakers' ? 'active_topbar__item' : ''
                        }`}
                        onClick={() => handleNavLinkClick('speakers')}
                    > 
                        Speakers 
                    </Link>
                    <Link to="/conference/community/authors" className={`topbar__item ${
                        location.pathname === '/conference/community/authors' ? 'active_topbar__item' : ''
                        }`}
                        onClick={() => handleNavLinkClick('authors')}

                    > 
                        Authors 
                    </Link>
                    <Link to="/conference/community/organizations" className={`topbar__item ${
                        location.pathname === '/conference/community/organizations' ? 'active_topbar__item' : ''
                        }`}
                        onClick={() => handleNavLinkClick('organizations')}
                    > 
                        Organizations 
                    </Link>
                    <Link to="/conference/community/attendees" className={`topbar__item ${
                        location.pathname === '/conference/community/attendees' ||
                        location.pathname === '/conference/Community/attendees/addattendee'
                        ? 'active_topbar__item' : ''
                        }`}
                        onClick={() => handleNavLinkClick('attendees')}

                    > 
                        Attendees 
                    </Link>

                    </div>

                    <Outlet />
                </div>

            </WrappedComponent>
        </div>
    );

}

export default Community;
