import React, {useEffect, useState} from 'react';
import '../../styles/Speakers.css'
import profileImage from '../../assets/images/community/speaker.jpeg';

import SpeakerItem from "./SpeakerItem";
import AddSpeakerForm from "./AddSpeakerForm";

import {useLocation, useNavigate} from "react-router-dom";

import Spinner from "../common/Spinner";

import { db } from "../../firebase";
import { collection, query, where, getDocs } from "firebase/firestore";



function Speakers  ()  {

    const [isLoading, setIsLoading] = useState(false);

    const [speakerMembers, setspeakerMembers] = useState([]);

    useEffect(() => {
      setIsLoading(true);
      // Create a query to get documents with "ATTENDEE" in their "role" attribute
      const q = query(collection(db, "participant"), where("role", "array-contains", "SPEAKER"));
  
      // Fetch documents that match the query
      getDocs(q)
        .then((querySnapshot) => {
          const speakerData = [];
          querySnapshot.forEach((doc) => {
            // Extract data from each document
            const data = doc.data();
            speakerData.push({
              id: doc.id,
              firstName: data.firstName,
              lastName: data.lastName,
              email: data.email,
              // Add other attributes as needed
            });
          });
          setspeakerMembers(speakerData);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error getting speakers: ", error);
          setIsLoading(false);
        });
    }, []);




  return (
    <div className="speakersPage__content">
        <div className="speakersPage__left">
            

            {isLoading ? (
                        <Spinner />
            ) : speakerMembers.length > 0 ? (
                speakerMembers.map((speaker) => (
                    <div key={speaker.id} className='speakers__items'>
                        <SpeakerItem
                            firstName={speaker.firstName}
                            lastName={speaker.lastName}
                            attendee={speaker}
                        />
                    </div>
                ))
            ) : (
                <h3>There are no Speakers</h3>
            )}
        </div>
        <div className="speakersPage__right">
            <AddSpeakerForm />
        </div>
    </div>
  );
};

export default Speakers;
