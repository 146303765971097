import React, { useState } from 'react';
import profile from "../../assets/images/community/profile.jpg";
import { db } from '../../firebase';
import { doc, updateDoc } from 'firebase/firestore';

function SessionChatCard({ question, onAnsweredChange }) {
  const [isChecked, setIsChecked] = useState(question.isAnswered || false);

  const handleCheckboxChange = async (isChecked) => {
    setIsChecked(isChecked); // Update the local state

    try {
      const docRef = doc(db, 'Questions', question.questionId);

      // Update the Firestore document with isAnswered based on the checkbox state
      await updateDoc(docRef, { isAnswered: isChecked });

      // Successfully updated the Firestore document
      onAnsweredChange && onAnsweredChange(question.questionId, isChecked); // Call the callback function if it exists
    } catch (error) {
      alert("Error updating document: " + error);
    }
  }

  return (
    <div>
      <div className="CQuestionCard-header">
        <img src={profile} alt="Profile" className="profile-image" />
        <div className="CQuestionCardprofile-info">
          <p className="CQuestionCardname">{question.firstname} {question.lastname}</p>
          <p className='ChatTimestamp2'>{question.time.toString()}</p>
        </div>
      </div>
      <div className="CQuestionCard-content">
        <p className="CQuestion">{question.content}</p>
      </div>
      <div className="CheckedAnswered">
        <label className='CheckedAnswerL'>
          <p>Answered</p>
          <input
            type="checkbox"
            checked={isChecked}
            onChange={(e) => handleCheckboxChange(e.target.checked)}
          />
        </label>
      </div>
    </div>
  );
}

export default SessionChatCard;
