import React, { useState, useEffect, useRef } from 'react';
import '../../styles/SessionChat.css';
import { collection, getDocs, query, where, onSnapshot, orderBy } from 'firebase/firestore';
import { db } from '../../firebase';
import Spinner from '../../components/common/Spinner';
import SessionChatCard from './SessionChatCard';

function SessionChat({ sessionID }) {
  const [questions, setQuestions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const questionCollectionRef = collection(db, 'Questions');
    const q = query(
      questionCollectionRef,
      where('sessionId', '==', sessionID),
      orderBy('time', 'desc') // Sort by 'time' in descending order
    );

    const unsubscribe = onSnapshot(q, (qSnapshot) => {
      const questionData = [];
      qSnapshot.forEach((doc) => {
        const data = doc.data();
        const senderData = data.sender;

        // Check if isAnswered exists, if not, set it to false
        if (!data.isAnswered) {
          data.isAnswered = false;
        }

        questionData.push({
          questionId: doc.id,
          sender: senderData,
          firstname: senderData.firstname,
          lastname: senderData.lastname,
          time: data.time.toDate(),
          content: data.content,
          isAnswered: data.isAnswered,
        });
      });

      // Sort the questions, placing isAnswered === true at the bottom
      const sortedQuestions = questionData.sort((a, b) => {
        if (a.isAnswered && !b.isAnswered) {
          return 1;
        } else if (!a.isAnswered && b.isAnswered) {
          return -1;
        }
        // If both have the same isAnswered value or neither have it, sort by time
        return b.time - a.time;
      });

      setQuestions(sortedQuestions);
      setIsLoading(false);
    }, (error) => {
      console.error('Error fetching sessions:', error);
      setIsLoading(false);
    });

    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, [sessionID]);

  // Create a ref for the scrollable content element
  const scrollableContentRef = useRef(null);

  // Use the useEffect hook to scroll to the bottom when new content is added
  // useEffect(() => {
  //   if (scrollableContentRef.current) {
  //     scrollableContentRef.current.scrollTop = 0;
  //   }
  // }, [questions]);

  return (
    <div className="SessionChat">
      <div className="SessionChatTopic">
        <p className="ChatQA">Q&A</p>
        <p className="NoQuestions">{questions.length} Questions</p>
      </div>
      <div className="ScrollableContent" ref={scrollableContentRef}>
        {isLoading ? (
          // Show a loading indicator while loading
          <Spinner />
        ) : questions.length > 0 ? (
          // Map over the questions and display them
          questions.map((question) => (
            <div className="CQuestionCard" key={question.questionId}>
              <SessionChatCard
                key={question.questionId}
                question={question}
              />
              <div></div>
            </div>
          ))
        ) : (
          // Show a message when there are no questions
          <p>No questions available.</p>
        )}
      </div>
    </div>
  );
}

export default SessionChat;
