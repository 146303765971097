import React, {useEffect, useState} from 'react';
import { db } from "../../firebase";
import { collection, query, where, getDocs } from "firebase/firestore";

import profileImage from '../../assets/images/community/OC.jpeg';

import OCMemberItem from "./OCMemberItem";
import AddOCMemberForm from "./AddOCMemberForm";

import '../../styles/OCMembers.css';


import {useLocation, useNavigate} from "react-router-dom";

import Spinner from "../common/Spinner";



function OCMembers() {

  const [isLoading, setIsLoading] = useState(false);
  const [ocMembers, setocMembers] = useState([]);

  useEffect(() => {
      // Create a query to get documents with "ATTENDEE" in their "role" attribute
      setIsLoading(true);
      const q = query(collection(db, "participant"), where("role", "array-contains", "OC"));
  
      // Fetch documents that match the query
      getDocs(q)
        .then((querySnapshot) => {
          const ocData = [];
          querySnapshot.forEach((doc) => {
            // Extract data from each document
            const data = doc.data();
            ocData.push({
              id: doc.id,
              firstName: data.firstName,
              lastName: data.lastName,
              email: data.email,
              // Add other attributes as needed
            });
          });
          setocMembers(ocData);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error getting OC memmbers: ", error);
          setIsLoading(false);
        });
  }, []);

  


//   const ocMembers = participants.filter(participant => participant.role === "OC");

  return (
    <div className='ocPage__content'>
      <div className="ocPage__left">
          
        {isLoading ? (
            <Spinner />
        ) : ocMembers.length > 0 ? (
            ocMembers.map((oc) => (
                <div key={oc.id} className='oc__items'>
                    <OCMemberItem
                        firstName={oc.firstName}
                        lastName={oc.lastName}
                        attendee={oc}
                    />
                </div>
            ))
        ) : (
            <h3>There are no OC Members</h3>
        )}
      </div>

           
      <div className="ocPage__right">
          <AddOCMemberForm />
      </div>

    </div>
  );
};

export default OCMembers;
