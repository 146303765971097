import "../../styles/AttendeeSessionItem.css";

function AttendeeSessionItem({ addAttendees }) {
  return (
    <div className="sessionCard-item">
      <div className="sessionCard-item__description">
        <div className="sessionCard-item-image">
          <img src={addAttendees.imageSrc} />
        </div>

        <h2>{addAttendees.title}</h2>
      </div>
      <div>
        <input type="checkbox" />
      </div>
    </div>
  );
}

export default AttendeeSessionItem;
