import '../../styles/AllContactItem.css';
import AllContactCardIcons from './AllContactCardIcons';

function AllContactItem({ contact }) {

    return (
        <div className='allcontactCard-item'>
            <div className='allcontactCard-item__description'>
                <div className='allcontactCard-item-image'>
                    <img src={contact.imageSrc} />
                </div>
            
                <h2>{contact.title}</h2>
            </div>
            <div>
                <AllContactCardIcons />
            </div>
        </div>
    );
}

export default AllContactItem;