import React from "react";
import "../../styles/ParticipationTable.css";
import { db } from "../../firebase";
import { collection, query, where, getDocs } from "firebase/firestore";
import searchIcon from "../../assets/images/community/search.svg";

class ResearcherParticipationTable extends React.Component {
  constructor() {
    super();
    this.state = {
      data: [],
      search: "", // Add a search state
    };
  }

  async fetchQuestionsCount(email) {
    try {
      const questionRef = collection(db, "Questions"); // Replace "questions" with the correct collection name
      const querySnapshot = await getDocs(
        query(questionRef, where("sender.email", "==", email))
      );
      const questionCount = querySnapshot.size;
      return questionCount;
    } catch (error) {
      console.error("Error fetching questions:", error);
      return 0;
    }
  }

  async fetchData() {
    const dataRef = collection(db, "participant");

    try {
      const querySnapshot = await getDocs(dataRef);
      const data = querySnapshot.docs.map((doc) => doc.data());

      const dataWithQuestionCounts = await Promise.all(
        data.map(async (participant) => {
          const questionCount = await this.fetchQuestionsCount(
            participant.email
          );
          return { ...participant, questionCount };
        })
      );

      // Sort the data in descending order of question count
      const sortedData = dataWithQuestionCounts.sort(
        (a, b) => b.questionCount - a.questionCount
      );

      this.setState({ data: sortedData });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  componentDidMount() {
    this.fetchData();
  }

  // search filter
  handleSearchChange = (event) => {
    this.setState({ search: event.target.value });
  };

  sortByNumberOfConnections = () => {
    const sortedData = [...this.state.data].sort((a, b) => {
      const aValue = a.isResearcher && a.contacts ? a.contacts.length : 0;
      const bValue = b.isResearcher && b.contacts ? b.contacts.length : 0;
      return bValue - aValue; // For descending order
    });
    this.setState({ data: sortedData });
  };

  sortByNumberOfQuestions = () => {
    const sortedData = [...this.state.data].sort((a, b) => {
      const aValue = a.isResearcher ? a.questionCount : 0;
      const bValue = b.isResearcher ? b.questionCount : 0;
      return bValue - aValue; // For descending order
    });
    this.setState({ data: sortedData });
  };

  render() {
    const currentPage = this.props.currentPage;
    const itemsPerPage = 60;
    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
  
    // Filter the data based on the search state
    const filteredData = this.state.data.filter(item => {
      const name = `${item.firstName} ${item.lastName}`.toLowerCase();
      const search = this.state.search.toLowerCase();
      return name.includes(search);
    });
  
    let sortedData = [...filteredData];

    if (
      this.state.sortColumn === "questions" ||
      this.state.sortColumn === "contacts"
    ) {
      sortedData = sortedData.sort((a, b) => {
        const aValue = a.isResearcher
          ? this.state.sortColumn === "questions"
            ? a.questionCount
            : a.contacts
            ? a.contacts.length
            : 0
          : 0;
        const bValue = b.isResearcher
          ? this.state.sortColumn === "questions"
            ? b.questionCount
            : b.contacts
            ? b.contacts.length
            : 0
          : 0;

        return this.state.sortOrder === "asc"
          ? aValue - bValue
          : bValue - aValue;
      });
    }

  const displayedData = sortedData.slice(startIndex, endIndex);

    return (
      <div className="participation-table">
        <div
          style={{
            marginBottom: 40,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <h3 className="participation-header">Participation & Engagement</h3>
          <div className="search-container">
            <input
              className="search"
              type="text"
              placeholder="Search Here"
              value={this.state.search}
              onChange={this.handleSearchChange}
            />
          </div>
          <div>
            <button
              onClick={this.sortByNumberOfConnections}
              style={{
                marginRight: 10,
                padding: 10,
                borderRadius: 8,
                backgroundColor: "#4CAF50" /* Green */,
                border: "none",
                color: "white",
                textAlign: "center",
                textDecoration: "none",
                display: "inline-block",
                fontSize: "16px",
                transitionDuration: "0.4s",
                cursor: "pointer",
              }}
              onMouseOver={(e) => (e.target.style.backgroundColor = "#45a049")}
              onMouseOut={(e) => (e.target.style.backgroundColor = "#4CAF50")}
            >
              Sort by Number of Connections
            </button>
            <button
              onClick={this.sortByNumberOfQuestions}
              style={{
                padding: 10,
                borderRadius: 8,
                backgroundColor: "#008CBA" /* Blue */,
                border: "none",
                color: "white",
                textAlign: "center",
                textDecoration: "none",
                display: "inline-block",
                fontSize: "16px",
                transitionDuration: "0.4s",
                cursor: "pointer",
              }}
              onMouseOver={(e) => (e.target.style.backgroundColor = "#007BBD")}
              onMouseOut={(e) => (e.target.style.backgroundColor = "#008CBA")}
            >
              Sort by Number of Questions
            </button>
          </div>
        </div>

        <div className="table-container">
          <table className="participation">
            <thead>
              <tr>
                <th className="participation-table-header column-name">Name</th>
                <th className="participation-table-header column-checkIn">
                  Check In
                  <br />
                  Day 01
                </th>
                <th className="participation-table-header column-checkIn">
                  Check In
                  <br /> Day 02
                </th>
                <th className="participation-table-header column-researcher">
                  I'm a <br />
                  researcher
                </th>
                <th className="participation-table-header column-tshirt">
                  T-shirt
                </th>
                <th className="participation-table-header column-bag">Bag</th>
                <th className="participation-table-header column-notebook">
                  Notebook
                </th>
                <th className="participation-table-header column-confokit">
                  ConfoKit
                </th>
                <th className="participation-table-header column-contacts">
                  Connections
                </th>
                <th
                  className={`participation-table-header column-questions ${
                    this.state.sortColumn === "questions" ? "sorted" : ""
                  }`}
                  onClick={() => this.handleSort("questions")}
                >
                  Number of
                  <br /> Questions
                </th>
              </tr>
            </thead>
            <tbody>
              {displayedData.map((item, index) => (
                <tr key={index} className="table-row">
                  <td className="table-cell-name">
                    {item.firstName} {item.lastName}
                    <br />
                    {item.email}
                  </td>
                  <td className="table-cell-check-in">
                    <input
                      type="checkbox"
                      readOnly
                      disabled
                      checked={item.day1CheckedIn}
                    />
                  </td>
                  <td className="table-cell-check-in">
                    <input
                      type="checkbox"
                      readOnly
                      disabled
                      checked={item.day2CheckedIn}
                    />
                  </td>
                  <td className="table-cell-researcher">
                    <input
                      type="checkbox"
                      readOnly
                      disabled
                      checked={item.isResearcher}
                    />
                  </td>
                  <td className="table-cell-tshirt">
                    <input
                      type="checkbox"
                      className="table-cell tshirt"
                      readOnly
                      disabled
                      checked={item.isTshirt}
                    />
                      {item.tSize}
                  </td>
                  <td className="table-cell-bag">
                    <input
                      type="checkbox"
                      readOnly
                      disabled
                      checked={item.isBag}
                    />
                  </td>
                  <td className="table-cell-notebook">
                    <input
                      type="checkbox"
                      readOnly
                      disabled
                      checked={item.isNotebook}
                    />
                  </td>
                  <td className="table-cell-confokit">
                    <input
                      type="checkbox"
                      readOnly
                      disabled
                      checked={item.isConfokit}
                    />
                  </td>
                  <td className="table-cell-contacts">
                    {item.isResearcher
                      ? item.contacts
                        ? item.contacts.length
                        : 0
                      : "-"}
                  </td>
                  <td className="table-cell-questions">
                    {item.isResearcher ? item.questionCount : "-"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default ResearcherParticipationTable;
