import { React, useState } from "react";
import "../styles/LoginRegister.css";

import { Link, useNavigate } from "react-router-dom";

// Import icons
import {
  AiFillLinkedin,
  AiFillFacebook,
  AiFillTwitterSquare,
} from "react-icons/ai";
import LoginImage from "../assets/images/all-users/LoginImg.svg";
import Email from "../assets/images/all-users/email.svg";
import Password from "../assets/images/all-users/password.svg";

// Import components
import DefaultHeader from "../components/common/DefaultHeader";

import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { useDispatch } from "react-redux";
import { login } from "../features/userSlice";
import { auth } from "./../firebase";

// Toastify erros
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // const [firstName, setfirstName] = useState('');
  const [error, setError] = useState(null); // Add an error state

  const signIn = async (e) => {
    e.preventDefault();

    try {
      // Sign in the user with Firebase authentication
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      // Check if the user's email is verified
      if (!user.emailVerified) {
        // Sign out the user
        await signOut(auth);

        // Display a message
        toast.error("Please verify your email before signing in", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        return;
      }

      // Dispatch the login action to update the user state
      dispatch(
        login({
          email: user.email,
          uid: user.uid,
          firstName: user.firstName,
          lastName: user.lastName,
          // displayName: user.displayName,
        })
      );

      // Redirect to the desired route (e.g., '/conference')
      navigate("/conference");
    } catch (error) {
      // Handle authentication errors
      // alert(error.message);
      toast.error("Invalid Login Credentials", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  return (
    <div className="container">
      <div className="middle-container">
        <DefaultHeader className="" />

        <div className="container-body">
          <div className="body-left">
            <img src={LoginImage} alt="screen img" className="screen-img" />
          </div>

          <hr />

          <div className="body-right">
            <p className="title">Log In</p>

            {/* * **************** Login Form * **************** */}

            <form onSubmit={signIn}>
              <div className="form">
                {/* * **************** Login Form  - Email* **************** */}

                <div className="form-items">
                  <div className="form-items-cat1">
                    <img src={Email} alt="" />
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={email}
                      placeholder="email"
                      onChange={(e) => setEmail(e.target.value)}
                      className="form-items__input"
                      required
                    />
                    <label htmlFor="email" className="form-items__label">
                      Email
                    </label>
                  </div>
                </div>

                {/* * **************** Login Form - Password * **************** */}

                <div className="form-items">
                  <div className="form-items-cat1">
                    <img src={Password} alt="" />
                    <input
                      type="password"
                      id="password"
                      name="password"
                      value={password}
                      placeholder=""
                      onChange={(e) => setPassword(e.target.value)}
                      className="form-items__input"
                    />
                    <label htmlFor="password" className="form-items__label">
                      Password
                    </label>
                  </div>
                </div>

                {/* * **************** Remember Me * **************** */}

                <div className="remember-me">
                  <input type="checkbox" />
                  <p>Remember me</p>
                </div>
                <div></div>
              </div>

              {/* * **************** Forget Password * **************** */}

              <Link to="/forget_password" className="forget_password">
                <h6>Forgot your password?</h6>
              </Link>

              <div className="body-right-bottom">
                {/* * **************** Login Form - Submit Button * **************** */}

                <button type="submit">Log In</button>

                <div className="register-link">
                  <p>Don't have an account?</p>
                  <Link to="/register">Sign Up</Link>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div className="social-icons-4">
          <AiFillFacebook className="social-icon" />
          <AiFillLinkedin className="social-icon" />
          <AiFillTwitterSquare className="social-icon" />
        </div>
      </div>

      {/* add toastify container -> don't remove this */}
      <ToastContainer />
    </div>
  );
}

export default Login;
