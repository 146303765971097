import React, {useState, useEffect} from "react";
import { useDispatch } from "react-redux";

import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";


import location from "../../assets/images/all-users/location.svg";
import mic from "../../assets/images/all-users/mic.svg";
import calendar from "../../assets/images/all-users/calendar.svg";

import "../../styles/ConferenceItem.css";
import moment from 'moment';
import Avatar from '@mui/material/Avatar';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
// import { storage } from '../../firebase';

function SessionCard({ session }) {
  const [imageURL, setImageURL] = useState(null);



  useEffect(() => {
    const fetchImage = async () => {
      let url;
      if (session.speakerImagePath) {
        const storage = getStorage();
        const gsReference = ref(storage, session.speakerImagePath);
        try {
          url = await getDownloadURL(gsReference);
          setImageURL(url);
        } catch (error) {
          console.error("Error getting download URL:", error);
        }
      } else {
        console.warn("session.speakerImagePath is not defined for session ID: ", session.sessionId);
        url = 'path/to/your/default/image.jpg'; // Replace with your default image path
      }
      setImageURL(url);
    };
    
    

    fetchImage();
  }, [session.speakerImagePath]);
  
  
  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineConnector />
        <TimelineDot />
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent className="timeline__content">
        <p>
        {session.startTime ? moment(session.startTime).format('LT') : ''} - {session.endTime ? moment(session.endTime).format('LT') : ''}
        </p>
        <div className="session__content__card">
          <div className="session-card--header">
            <Avatar src={imageURL} id="avatar_agenda"> {session.sessionName[0]} </Avatar>
          </div>
          <div className="session-details">
            <h5>{session.sessionName || "-"}</h5>
            {session.speaker !== undefined && (
              <div className="session-info--detail">
                <img src={mic} alt="Speaker" />
                <p>{session.speaker || "-"}</p>
              </div>
            )}
            <div className="session-info--detail">
              <img src={location} alt="Location" />
              <p>{session.location || "-"}</p>
            </div>
            <div className="session-info--detail">
              <img src={calendar} alt="Date" />
              <p>{session.date ? moment(session.date).format('LL') : "-"} </p>
            </div>
          </div>
          <div className="session__card-editable-buttons">
            {/* <button>
              <img src={EDIT} alt="Edit" />
            </button>
            <button>
              <img src={DELETE} alt="Delete" />
            </button> */}
          </div>
        </div>
      </TimelineContent>
    </TimelineItem>
  );
}

export default SessionCard;
