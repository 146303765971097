import React, { useState } from 'react';
import '../../styles/ChangePassword.css'

function ChangePassword({ onCancel, onSave }) {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  const handleSaveClick = () => {
    // Perform password change logic here and call onSave with the new password
    onSave(newPassword);
  };

  return (
    <div className='change-password-container'>
      <h4>Change your Password</h4>
      <div className='change-password-fields'>
        <div className='change-password-field'>
          <label htmlFor='currentPassword'>Current Password* :</label>
          <input
            type='password'
            id='currentPassword'
            value={currentPassword} required
            onChange={(e) => setCurrentPassword(e.target.value)}
          />
        </div>
        <div className='change-password-field'>
          <label htmlFor='newPassword'>New Password* :</label>
          <input
            type='password'
            id='newPassword'
            value={newPassword} required
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </div>
        <div className='change-password-field'>
          <label htmlFor='confirmNewPassword'>Confirm New Password* :</label>
          <input
            type='password'
            id='confirmNewPassword'
            value={confirmNewPassword} required
            onChange={(e) => setConfirmNewPassword(e.target.value)}
          />
        </div>
      </div>
  
    </div>
  );
}

export default ChangePassword;
