import React, { useState } from 'react';
import '../styles/UserProfileAttendee.css';

import { Link } from 'react-router-dom';

import EDIT from '../assets/images/all-users/editIcon.svg';
import CUT from '../assets/images/all-users/cutIcon.svg'; 
import AttachmentIcon from '../assets/images/all-users/AttachmentIcon.svg';
import Avatar from '@mui/material/Avatar';

import ChangePassword from '../components/my_profile/ChangePassword';

import PageLayout from '../layout/PageLayout1';

function UserProfile() {
  const WrappedComponent = PageLayout;

  const [editMode, setEditMode] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false); // State to control the Change Password component

  const handleEditClick = () => {
    setEditMode(!editMode);
    setShowChangePassword(false); // Hide the Change Password component when switching to edit mode
  };

  const handleChangePasswordClick = () => {
    setShowChangePassword(true);
  };

  const handleCancelChangePassword = () => {
    setShowChangePassword(false);
  };

  const handleSaveChangePassword = (newPassword) => {
    
    console.log('New password:', newPassword);

    // Close the Change Password component
    setShowChangePassword(false);
  };

  return (
    <div>
      <WrappedComponent>
        <div className='UserProfile-page'>
          <div className='UserProfile-text'>
            <div className='profile-text'>
              <h3>Profile</h3>
            </div>
            <Link to="">
              <img
                src={editMode ? CUT : EDIT} // Toggle between CUT and EDIT icons
                alt=""
                srcSet=""
                className='edit__button'
                onClick={handleEditClick}
              />
            </Link>
          </div>

          <div className='UserProfile__content'>
            <div className='user__bio'>
              <Avatar className='user__profile-picture' />
              <div className='__bio'>
                <label>Bio :</label>
                <textarea type="text" placeholder='Add a bio' readOnly={!editMode} />
              </div>
            </div>

            <hr />

            <div className='user__details'>
              <div className='__user'>
                {/* name */}
                <h2>Shanaka Fernando</h2>
                {/* designation */}
                <h6>Attendee</h6>
              </div>

              <form action="" className='user__form'>
                <div className='user__name'>
                  <div className='user__detail'>
                    <label htmlFor="">First Name : </label>
                    <input type="text" readOnly={!editMode} />
                  </div>
                  <div className='user__detail'>
                    <label htmlFor="">Last Name : </label>
                    <input type="text" readOnly={!editMode} />
                  </div>
                </div>

                <div className='user__detail mobile'>
                  <label htmlFor="">Mobile No : </label>
                  <input type="tel" readOnly={!editMode} />
                </div>

                <div className='user__detail email'>
                  <label htmlFor="">Email : </label>
                  <input type="tel" readOnly={!editMode} />
                </div>
                <div className='user__detail password'>
                  <label htmlFor="">Password : </label>
                  <input type="password" readOnly={!editMode} />
                  {editMode && (
                    <button type="button" className='edit-password-button' onClick={handleChangePasswordClick}>Edit</button>
                  )}
                </div>
                {/* Display Change Password component if showChangePassword is true */}
                {showChangePassword && (
                  <ChangePassword
                    onClose={handleCancelChangePassword}
                    onSave={handleSaveChangePassword}
                  />
                )}

                <h4>Social Accounts</h4>
                <div className='user__detail socialAccounts'>
                  <img src={AttachmentIcon} alt="" />
                  <input type="text" placeholder='Link to social profile' readOnly={!editMode} />
                </div>

                <div className='user__detail socialAccounts'>
                  <img src={AttachmentIcon} alt="" />
                  <input type="text" placeholder='Link to social profile' readOnly={!editMode} />
                </div>

                <div className='user__detail socialAccounts'>
                  <img src={AttachmentIcon} alt="" />
                  <input type="text" placeholder='Link to social profile' readOnly={!editMode} />
                </div>

                <div className='user__profile__buttons'>
                  {editMode && (
                    <>
                      <button className='cancel__btn'>Cancel</button>
                      <button className='save__btn'>Save</button>
                    </>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </WrappedComponent>
    </div>
  );
}

export default UserProfile;
