import '../../styles/OrganizationItem.css';
import Icons from '../community/Icons';
import profileImage from '../../assets/images/community/speaker.jpeg';


function OrganizationItem({ firstName }) {

    return (
        <div className='organizationCard-item'>
            <div className='organizationCard-item__description'>
                <div className='organizationCard-item-image'>
                    <img src={profileImage} />
                </div>
            
                <h2>{firstName}</h2>
            </div>
            <div>
                <Icons />
            </div>
        </div>
    );
}

export default OrganizationItem;