import React from 'react';
import '../../styles/Events.css';
import LOCATION from '../../assets/images/all-users/location.svg'
import CLOCK from '../../assets/images/all-users/clock.svg'
import CALENDAR from '../../assets/images/events/calendar.svg'
import EDIT from '../../assets/images/events/edit.svg'
import DELETE from '../../assets/images/events/delete.svg'
import { deleteDoc, doc } from 'firebase/firestore';
import { db } from '../../firebase'


function SessionEvents({id, eventName, organization, date, startTime, endTime, location, description, isCompetition }) {
    
    const handleDelete = async () => {
        const docRef = doc(db, 'Events', id);

        try {
            await deleteDoc(docRef);
            console.log('Document deleted successfully');
        } catch (error) {
            console.error('Error deleting document: ', error);
        }
    }
    
    return (
        <div className='session__events'>
            <div className='__event-card'>
                <div className="event-card--header">
                    <img src={CALENDAR} alt="" className='calendarIcon' />
                </div>
                <div className="event-details">
                    <h5>{eventName}</h5>
                    <h6>{organization}</h6>
                    <div className='event-info--detail'>
                        <img src={CALENDAR} alt="" />
                        <p>{new Date(date.seconds * 1000).toLocaleDateString()}</p>
                    </div>
                    <div className='event-info--detail'>
                        <img src={CLOCK} alt="" />
                        <p>
                            {startTime && 'seconds' in startTime && new Date(startTime.seconds * 1000).toLocaleTimeString()} -
                            {endTime && 'seconds' in endTime && new Date(endTime.seconds * 1000).toLocaleTimeString()}
                        </p>

                    </div>
                    <div className='event-info--detail'>
                        <img src={LOCATION} alt="" />
                        <p>{location}</p>
                    </div>
                    <div className='event-info--detail'>
                        <p>{description}</p>
                    </div>
                    {isCompetition && (
                        <div className='event-info--detail'>
                            <p className='event-type'>Competition</p>
                        </div>
                    )}

                </div>
                <div className='event__card-editable-buttons'>
                    <button onClick={handleDelete}><img src={DELETE} alt="" /></button>
                </div>
            </div>
        </div>
    )


}

export default SessionEvents
