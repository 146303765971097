import React, {useEffect, useState} from 'react';
import '../../styles/Organizations.css'


import { db } from "../../firebase";
import { collection, query, where, getDocs } from "firebase/firestore";

import OrganizationItem from "./OrganizationItem";
import AddOrganizationForm from "./AddOrganizationForm";

import Spinner from "../common/Spinner";



function Organizations  ()  {

    const [isLoading, setIsLoading] = useState(false);
    const [organizations, setorganizations] = useState([]);

    useEffect(() => {
      setIsLoading(true);
      const q = query(collection(db, "participant"), where("role", "array-contains", "ORGANIZATION"));
  
      // Fetch documents that match the query
      getDocs(q)
        .then((querySnapshot) => {
          const organizationData = [];
          querySnapshot.forEach((doc) => {
            // Extract data from each document
            const data = doc.data();
            organizationData.push({
              id: doc.id,
              firstName: data.firstName,
              email: data.email,
              // Add other attributes as needed
            });
          });
          setorganizations(organizationData);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error getting attendees: ", error);
          setIsLoading(false);
        });
    }, []);
  



  return (
    <div className="organizationsPage__content">
        <div className="organizationsPage__left">
            

            {isLoading ? (
                <Spinner />
            ) : organizations.length > 0 ? (
                organizations.map((org) => (
                    <div key={org.id} className='organizations__items'>
                        <OrganizationItem
                            firstName={org.firstName}
                            org={org}
                        />
                    </div>
                ))
            ) : (
                <h3>There are no organizations</h3>
            )}  
        </div>
        <div className="organizationsPage__right">
            <AddOrganizationForm />
        </div>
    </div>
  );
};

export default Organizations;
