import {React, useEffect, useState} from 'react';
import '../styles/ForgetPassword.css'

import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";

// Import features

// Import icons
import { AiFillLinkedin, AiFillFacebook, AiFillTwitterSquare} from 'react-icons/ai';
import ResetPasswordImage from '../assets/images/all-users/ResetPasswordImg.svg';
import Password from '../assets/images/all-users/password.svg';

// Import components
import DefaultHeader from '../components/common/DefaultHeader';
import Spinner from "../components/common/Spinner";

function ResetPassword() {

    

    // if (isLoading) {
    //     return <Spinner />;
    // }


  return (
    <div className='container'>
            {/* <div className='middle-container'>
                <DefaultHeader className='forget-password-container-header' />

                <div className='forget-password-container-body'>
                    <div className='forget-password-body-left'>
                        <img src={ResetPasswordImage} alt="screen img" className='screen-img' />

                    </div>

                    <hr />

                    <div className='forget-password-body-right'>
                        <h4 className='title'>Reset Password</h4>

                        <hr />


                        <form className='foreget-password--form' onSubmit={onSubmit}>

                            <div className='foreget-password-form'>


                                <div className='foreget-password-form-items'>
                                    <img src={Password} alt="" />
                                    <input
                                        placeholder='Password'
                                        id="password"
                                        name="password"
                                        value={password}
                                        onChange={onChange}

                                    />                                    
                                </div>


                                <div className='foreget-password-form-items'>
                                    <img src={Password} alt="" />
                                    <input
                                        type="password"
                                        id="re_password"
                                        name="re_password"
                                        value={re_password}
                                        placeholder='Re-enter Password'
                                        onChange={onChange}
                                    />
                                </div>
                            </div>


                            <div className='password-body-right-bottom'>


                                <button  type='submit' >Reset Password</button>

                            </div>

                        </form>

                    </div>
                </div>

                <div className='social-icons-4'>
                    <AiFillFacebook className='social-icon'/>
                    <AiFillLinkedin className='social-icon'/>
                    <AiFillTwitterSquare className='social-icon'/>
                </div>



            </div> */}
        </div>
  )
}

export default ResetPassword;