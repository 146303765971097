import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import '../styles/Agenda.css';

import { collection, getDocs, query, where, orderBy, QuerySnapshot } from 'firebase/firestore';
import { db } from '../firebase';

import { useLocation, useNavigate } from 'react-router-dom';

import { timelineItemClasses } from "@mui/lab/TimelineItem";
import Timeline from "@mui/lab/Timeline";

import Spinner from '../components/common/Spinner';
import SessionCard from '../components/agenda/SessionCard';
import SessionCalendar from '../components/agenda/SessionCalendar';
import ViewSession from '../components/agenda/ViewSession';

import PageLayout from '../layout/PageLayout2';

import addSession from "../assets/images/events/addEvent.svg";
import AddSession from '../components/agenda/AddSessionForm';


function Agenda() {
  const [showForm, setShowForm] = useState(false);
  const [sessions, setSessions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [selectedSession, setSelectedSession] = useState(null);
  const [showViewSession, setShowViewSession] = useState(false);

  const [activeDay, setActiveDay] = useState(2); // Initially set to Day 01

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const sessionsCollectionRef = collection(db, 'Sessions');
    const q = query(sessionsCollectionRef, where('day', '==', activeDay), orderBy('startTime'));

    getDocs(q)
      .then((qSnapshot) => {
        const sessionData = [];
        qSnapshot.forEach((doc) => {
          const data = doc.data();
          sessionData.push({
            sessionId: doc.id,
            date: data.date ? data.date.toDate() : null,
            day: data.day,
            endTime: data.endTime ? data.endTime.toDate() : null,
            location: data.location,
            sessionName: data.sessionName,
            speaker: data.speaker,
            speakerImagePath: data.speakerImagePath,
            startTime: data.startTime ? data.startTime.toDate() : null,
          });
        });
        
        setSessions(sessionData);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching sessions: ', error);
        setIsLoading(false);
      });
  }, [activeDay]); // Listen for changes to activeDay
 

  const WrappedComponent = PageLayout;

  return (
    <>
      <WrappedComponent >
        <div className="agendaPage">
          <div className='agendaPage__left'>
            <Outlet />
            {!location.pathname.startsWith('/conference/agenda/session_chat/') && (
              <>
                <h3>Agenda</h3>
                <div className='agenda__content'>
                <div className='event__selection'>
                  <button onClick={() => setActiveDay(1)} className={activeDay === 1 ? 'buttonactive' : ''}>
                    <h4>Day 01</h4>
                  </button>
                  <button onClick={() => setActiveDay(2)} className={activeDay === 2 ? 'buttonactive' : ''}>
                    <h4>Day 02</h4>
                  </button>
                </div>
                  <div className="session__card">
                    <Timeline
                      sx={{
                        [`& .${timelineItemClasses.root}:before`]: {
                          flex: 0,
                          padding: 0,
                        },
                      }}
                      className="__timeline"
                    >
                      {isLoading ? (
                        <Spinner />
                      ) : sessions.length > 0 ? (
                        <div className='items'>
                          {sessions.map((session) => (
                            <div key={session.sessionId}>
                              {session.speaker !== undefined ? (
                                <Link to={`/conference/agenda/session_chat/${session.sessionId}`} state={{ session: session }}>
                                  <SessionCard
                                    className = 'sessioncardInterval'
                                    key={session.sessionId}
                                    session={session}
                                    onClick={() => {
                                      setSelectedSession(session);
                                      setShowViewSession(true);
                                    }}
                                  />
                                </Link>
                              ) : (
                                <SessionCard
                                  session={session}
                                  onClick={() => {
                                    setSelectedSession(session);
                                    setShowViewSession(true);
                                  }}
                                />
                              )}
                          </div>
                        ))}
                      </div>
                    ) : (
                      <h3>No sessions</h3>
                    )}

                    </Timeline>
                  </div>
                  {/* {!showForm && (
                    <button onClick={() => setShowForm(true)}>
                      <img src={addSession} alt="" />
                    </button>
                  )}
                  {showForm && (
                    <div className="modal">
                      <div className="modal-content">
                        <AddSession setShowForm={setShowForm} />
                      </div>
                    </div>
                  )} */}
                </div>
              </>
            )}
          </div>
          <hr />
          <div className='agendaPage__right'>
            <div className='__calendar'>
              <SessionCalendar />
            </div>
          </div>
        </div>
      </WrappedComponent>
    </>
  );
}

export default Agenda;
