import React, { useState } from "react";
import "../../styles/Create_notification.css";
import BUTTON from "../../assets/images/notification/send_icon.png";
import { addDoc, collection, Timestamp } from 'firebase/firestore';
import { db} from '../../firebase'

function NotificationComponent() {
  const [newNotification, setNewNotification] = useState("");
  const [notifications, setNotifications] = useState([]);

  const handleNotificationChange = (event) => {
    setNewNotification(event.target.value);
  };

  const handleAddNotification = async () => {
    if (newNotification.trim() !== "") {
      const now = new Date();
      const day = now.getDate();
      const month = now.toLocaleString("en-US", { month: "long" });
      const year = now.getFullYear();
      const newNotificationObject = {
        content: newNotification,
        date: Timestamp.fromDate(now),
        time: Timestamp.fromDate(now),
      };

      try {
        await addDoc(collection(db, 'Announcements'), newNotificationObject);
        console.log('Document added successfully');
      } catch (error) {
        console.error('Error adding document: ', error);
      }

      setNewNotification("");
    }
  };

  const formatTimeAgo = (Timestamp) => {
    const now = new Date().getTime();
    const timeDiff = now - Timestamp;

    if (timeDiff >= 3600000) {
      const hours = Math.floor(timeDiff / 3600000);
      return `${hours}h ago`;
    } else {
      const minutes = Math.floor(timeDiff / 60000);
      return `${minutes}min ago`;
    }
  };

  const ordinalSuffix = (number) => {
    const j = number % 10,
      k = number % 100;
    if (j === 1 && k !== 11) {
      return number + "st";
    }
    if (j === 2 && k !== 12) {
      return number + "nd";
    }
    if (j === 3 && k !== 13) {
      return number + "rd";
    }
    return number + "th";
  };

  return (
    <div className="notification-container">
      <div className="create--notification">
        <div className="fixed-bar">
          <input
            type="text"
            value={newNotification}
            onChange={handleNotificationChange}
            placeholder="Enter new notification"
          />
          <button onClick={handleAddNotification}>
            <img src={BUTTON} alt="" className="BUTTON" />
          </button>
        </div>
      </div>
      <div className="notification-list">
        {notifications.slice().reverse().map((notification, index) => (
          <div key={index} className="notification">
            <div className="notification-date">{notification.date}</div>
            <div className="notification-time">
              {formatTimeAgo(notification.time)}
            </div>
            <div className="notification-text">{notification.text}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default NotificationComponent;
