import React, { useState, useEffect } from 'react'
import '../styles/Events.css'
import { useLocation } from "react-router-dom";
import { timelineItemClasses } from "@mui/lab/TimelineItem";
import Timeline from "@mui/lab/Timeline";
import addEvent from "../assets/images/events/addEvent.svg";
import AddEventForm from "../components/events/AddEvent";
import EventCard from '../components/events/SessionEvents';
import SessionCalendar from '../components/agenda/SessionCalendar';
import PageLayout from '../layout/PageLayout2';
import { onSnapshot, collection } from 'firebase/firestore';
import { db } from '../firebase'

function Event() {

  const location = useLocation();
  const conferenceId = location.state?.conferenceId;

  const [showForm, setShowForm] = useState(false);

  const WrappedComponent = PageLayout;
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const fetchEvents = () => {
      const eventsCollection = collection(db, 'Events');
      onSnapshot(eventsCollection, (snapshot) => {
        const eventList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setEvents(eventList);
      });
    };

    fetchEvents();
  }, []);

  return (
    <WrappedComponent conferenceId={conferenceId}>

      <div className="eventPage">
        <div className='eventPage__left'>
          <h3>Events</h3>
          <div className='event__content'>
            {/* <div className='event__selection'>
                <button><h4>All Events</h4></button>
                Wanna add after connected to the backend
                <button><h4>My Events</h4></button> 
              </div> */}

            <div className='event_cards'>
              {events.map((event, index) => (
                <EventCard key={index} {...event} />
              ))}

            </div>

            {!showForm && (
              <button onClick={() => setShowForm(true)}>
                <img src={addEvent} alt="" />
              </button>
            )}
            {showForm && (
              <div className="modal">
                <div className="modal-content">
                  <AddEventForm setShowForm={setShowForm} />
                </div>
              </div>
            )}

          </div>
        </div>
        <hr />
        <div className='eventPage__right'>
          <div className='__calendar'>
            <SessionCalendar />
          </div>
        </div>
      </div>


    </WrappedComponent>
  )
}

export default Event
