import React, { useState } from "react";

import profileImage from "../../assets/images/all-users/conforencelogo1.svg";
import SearchBox from "./SearchBox";
import TopBar from "./TopBar";

import AttendeeSessionItem from "./AttendeeSessionItem";
import AddAttendeeForm from "./AddAttendeeForm";

import PageLayout from "../../layout/PageLayout2";
import "../../styles/AddAttendee.css";
import {useLocation} from "react-router-dom";

function AddAttendees() {
  const [selectAll, setSelectAll] = useState(false);
  const [sessionCheckStates, setSessionCheckStates] = useState({});
  const location = useLocation();

  const conferenceId = location.state?.conferenceId;

  //ToDo: get OCMembers from backend
  const addattendees = [
    {
      id: "e4",
      title: "Day 01 - 8th of November",
      imageSrc: profileImage,
    },
    {
      id: "e5",
      imageSrc: profileImage,
      title: "Day 02 - 9th of November",
    },
    // {
    //   id: "e7",
    //   title: "Cyber Security",
    //   imageSrc: profileImage,
    // },
    // {
    //   id: "e10",
    //   title: "Database Management Systems",
    //   imageSrc: profileImage,
    // },
  ];

  const WrappedComponent = PageLayout;
  const handleSelectAllChange = () => {
    const updatedCheckStates = {};

    if (!selectAll) {
      // Select All
      addattendees.forEach((session) => {
        updatedCheckStates[session.id] = true;
      });
    } else {
      // Deselect All
      addattendees.forEach((session) => {
        updatedCheckStates[session.id] = false;
      });
    }

    setSessionCheckStates(updatedCheckStates);
    setSelectAll(!selectAll);
  };
  const handleSessionCheckChange = (sessionId, isChecked) => {
    const updatedCheckStates = {
      ...sessionCheckStates,
      [sessionId]: isChecked,
    };
    setSessionCheckStates(updatedCheckStates);
  };
  return (
    <div className="addAttendeePage__content">
      <div className="addAttendeePage__left">
        <h4 className="session-header">Select the days</h4>

        <div>
          {/* Step 2: "Select All" radio button
          <label class="add-attendee-label-container">
            <input
              type="checkbox"
              checked={selectAll}
              onChange={handleSelectAllChange}
              class="checkbox-input"
            />
            Select All
          </label> */}
          
        </div>
        {addattendees.length > 0 ? (
          <div className="addAttendee__items">
            {addattendees.map((addAttendees) => (
              <AttendeeSessionItem
                key={addAttendees.id}
                addAttendees={addAttendees}
                checked={sessionCheckStates[addAttendees.id] || false}
                onCheckChange={(isChecked) =>
                  handleSessionCheckChange(addAttendees.id, isChecked)
                }
              />
            ))}
          </div>
        ) : (
          <h3>There is no OC Members</h3>
        )}
      </div>
      <div className="addAttendeePage__right">
        <AddAttendeeForm />
      </div>
    </div>
  );
}

export default AddAttendees;
