import React, { useState, useEffect } from 'react';
import '../../styles/Header.css';

import { Link } from 'react-router-dom';

// Import icons
import logo from '../../assets/images/header/confolink.svg';
import Avatar from '@mui/material/Avatar';
import {AiFillCaretDown} from 'react-icons/ai'  

import { useDispatch } from 'react-redux';
import { logout , login } from '../../features/userSlice';
import { auth, db } from '../../firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';  // Updated import statements


import { useSelector } from 'react-redux';
import { selectUser } from '../../features/userSlice';


const Header = () => {

  
  const user = useSelector(selectUser);
  const dispatch = useDispatch();

  const onLogout = () => {
    dispatch(logout());
    auth.signOut();
  };

  useEffect(() => {
    // Check if there's a logged-in user
    if (auth.currentUser) {
      const userEmail = auth.currentUser.email;
      const usersRef = collection(db, 'Users'); // Create a Firestore collection reference
  
      const q = query(usersRef, where('email', '==', userEmail)); // Create a query
  
      getDocs(q)
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            // Retrieve the user's data and set it in the state
            const userData = doc.data();
  
            // Dispatch the login action to update the Redux state
            dispatch(
              login({
                email: auth.currentUser.email,
                uid: auth.currentUser.uid,
                firstName: userData.firstName,
                lastName: userData.lastName,
              })
            );
          });
        })
        .catch((error) => {
          console.error('Error fetching user data: ', error);
        });
    }
  }, []);

  
    // Check if there's a logged-in user
    
  


  return (
    <header>
      <div className="header-items">
        <img src={logo} alt="" className="logo" /> {/* Make sure to import 'logo' appropriately */}

        <div className="header-items-right">
          
          <div className="user-details">
            <p className="user-name">
              {user.firstName} {user.lastName}
            </p>
            <p className="user-designation">{user.email}</p>
          </div>
          

          <div className="profile-dropdown">
            <button className="user-image-dropdown-btn">
              <Avatar src={user.photoUrl} className='profile-picture'> {user.email[0]} </Avatar>
              <AiFillCaretDown className="dropdown-arrow-header" />
            </button>
            <div className="profile-dropdown-content">
              {/* <Link to="/user_profile_attendee">My Account</Link> */}
              {/* <a href="#">Contact Us</a> */}
              <button onClick={onLogout}>Logout</button>
            </div>
          </div>
        </div>
      </div>
      <hr className="end-line" />
    </header>
  );
};

export default Header;
