import React from 'react';
import PageLayout from '../layout/PageLayout2';
import QACard from "../components/Q&A/Q&ACardItem";
import '../styles/Q&A.css'
import {useLocation} from "react-router-dom";


function QA() {

  const location = useLocation();
  const conferenceId = location.state?.conferenceId;

    const WrappedComponent = PageLayout; 
  return (
<div>
    <WrappedComponent conferenceId={conferenceId}>
        
      <div className="QandAPage">
        <div className='QandAPage__header'>
          <h3 className="QandA-header">OC HelpLine</h3>   
        </div>
          

        <div className="App">
          <QACard
            question="How do I create a Q&A card form using React?"
            name="Nirupama Dissanayake"
            profileImage="../../assets/images/community/speaker.jpeg"
            timestamp="August 11, 2023"
          />
          <QACard
            question="What are the benefits of using React for web development?"
            name="Anuradha Basnayake"
            timestamp="August 12, 2023"
          />
        </div>
      </div>
    </WrappedComponent>
</div>    
  );
}

export default QA;



