import { Link, NavLink } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, Outlet } from "react-router-dom";
import { db } from "../../firebase";
import { collection, query, where, getDocs } from "firebase/firestore";
import SearchBox from "./SearchBox";
import AttendeeItem from "./AttendeeItem";
import "../../styles/Attendees.css";
import "../../styles/Pagination.css";
import PageLayout from "../../layout/PageLayout2";
import Spinner from "../common/Spinner";
import ReactPaginate from "react-paginate";

function Attendees() {

  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10; // Number of items per page
  const navigate = useNavigate();
  const location = useLocation();
 



  const [attendees, setAttendees] = useState([]);
  const [pageCount, setPageCount] = useState(0);

  

  useEffect(() => {
    setIsLoading(true);
    const q = query(
      collection(db, "participant"),
      where("role", "array-contains", "ATTENDEE")
    );

    getDocs(q)
      .then((querySnapshot) => {
        const attendeeData = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          attendeeData.push({
            id: doc.id,
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
          });
        });
        setAttendees(attendeeData);
        setIsLoading(false);
        setPageCount(Math.ceil(attendeeData.length / itemsPerPage));
        
      })
      .catch((error) => {
        console.error("Error getting attendees: ", error);
        setIsLoading(false);
      });
  }, []);

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedAttendees = attendees.slice(startIndex, endIndex);

  return (
    <>
      <Outlet />

      {location.pathname !== "/conference/Community/attendees/addattendee" && (
        <>
          <Link
            to="/conference/Community/attendees/addattendee"
            
          >
            <div className="attendeeButton_plus"></div>
          </Link>

          <div className="attendeePage__content">
            {isLoading ? (
              <Spinner />
            ) : displayedAttendees.length > 0 ? (
              displayedAttendees.map((attendee) => (
                <div key={attendee.id} className="attendee__card">
                  <AttendeeItem
                    firstName={attendee.firstName}
                    lastName={attendee.lastName}
                    attendee={attendee}
                  />
                </div>
              ))
            ) : (
              <h3>There are no Attendee members</h3>
            )}
          </div>
        </>
      )}

      <div className="pagination-container">
        {displayedAttendees.length > 0 && (
          <ReactPaginate
            previousLabel="Previous"
            nextLabel="Next"
            breakLabel="..."
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageChange}
            containerClassName="pagination"
            subContainerClassName="pages pagination"
            activeClassName="active"
          />
        )}
      </div>
    </>
  );
}

export default Attendees;
