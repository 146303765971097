import '../../styles/OCMemberItem.css';
import Icons from '../community/Icons';
import profileImage from '../../assets/images/community/OC.jpeg';

function OCMemberItem({ firstName, lastName }) {

    return (
        <div className='ocCard-item'>
            <div className='ocCard-item__description'>
                <div className='ocCard-item-image'>
                    <img src={profileImage} />
                </div>
            
                <h2>{firstName} {lastName}</h2>
            </div>
            <div>
                <Icons />
            </div>
        </div>
    );
}

export default OCMemberItem;