import React from 'react';
import '../../styles/DeletePopup.css'

const DeleteConfirmationPopup = ({ onCancel, onConfirm }) => {
  return (
    <div className="delete-confirmation-popup-container">
        <div className="delete-popup-section">
      <p>Are you sure you want to delete this?</p>
      <div className="delete-popup-buttons">
        <button className="delete-cancel"onClick={onCancel}>Cancel</button>
        <button className="delete-continue"onClick={onConfirm}>Continue</button>
      </div>
      </div>
    </div>
  );
};

export default DeleteConfirmationPopup;
