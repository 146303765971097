import React from "react";
import "../../styles/ProfileDetails.css";
import Work from "./ProfileWork";

const Details = () => {
  return (
    <div className="public_details">
      <div className="public_details_card">
        <h3>Busines Analyst </h3>
        <h3>Tech Enthusiast </h3>
        <br></br>
        <h3>Let's connect and collaborate on exciting software projects! </h3>
      </div>
      <div className="public_details_work">
        <h4>My Work: </h4>
      </div>
      <Work></Work>
    </div>
  );
};

export default Details;
