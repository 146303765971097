import React from 'react';
import Sidebar from '../components/common/NavbarStart.jsx';
import Header from '../components/common/Header.jsx';
import '../styles/PageLayout.css';

function PageLayout({ children }) {
  return (
    <div className='__page'>
      <Sidebar />
      <div className='pageRight'>
        <Header />
        <div className='pageContent'>
          {children}
        </div>
      </div>
    </div>
  );
}

export default PageLayout;
