import React, {useEffect, useState} from 'react';
import '../../styles/Navbar.css';

import { NavLink, useLocation } from 'react-router-dom';

// Import icons
import { AiFillLinkedin, AiFillFacebook, AiFillTwitterSquare } from 'react-icons/ai';
import QR from '../../assets/images/navbar/QR.svg';
import AGENDA from '../../assets/images/navbar/agenda.svg';
import AGENDA_ACTIVE from '../../assets/images/navbar/agendaFilled.svg';
import COMMUNITY from '../../assets/images/navbar/community.svg';
import COMMUNITY_ACTIVE from '../../assets/images/navbar/communityFilled.svg';
import CONTACTS from '../../assets/images/navbar/contacts.svg';
import CONTACTS_ACTIVE from '../../assets/images/navbar/contactsFIlled.svg';
import NOTIFICATIONS from '../../assets/images/navbar/notifications.svg';
import NOTIFICATIONS_ACTIVE from '../../assets/images/navbar/notificationsFilled.svg';
import QA from '../../assets/images/navbar/q&a.svg';
import QA_ACTIVE from '../../assets/images/navbar/q&aFilled.svg';
import ANALYTICS from '../../assets/images/navbar/analytics.svg';
import ANALYTICS_ACTIVE from '../../assets/images/navbar/analyticsFilled.svg';
import PARTICIPATION from '../../assets/images/navbar/participation.svg';
import PARTICIPATION_ACTIVE from '../../assets/images/navbar/ParticipationFilled.svg';
import EVENTS from '../../assets/images/navbar/events.svg';
import EVENTS_ACTIVE from '../../assets/images/navbar/eventsFilled.svg';
import BACK_ARROW from '../../assets/images/navbar/arrow_back.svg'

import QRCode from "react-qr-code";

import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../firebase';

import { useSelector } from 'react-redux';
import { selectUser } from '../../features/userSlice'



const SideNav = () => {
  const user = useSelector(selectUser);
  const [mainRole, setMainRole] = useState(null);

  useEffect(() => {
    const userEmail = user.email;
    const participantsRef = collection(db, 'participant');
    const emailQuery = query(participantsRef, where('email', '==', userEmail));

    getDocs(emailQuery)
      .then((querySnapshot) => {
        if (!querySnapshot.empty) {
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            const mainRole = data.main_role;
            setMainRole(mainRole);
          });
        } else {
          setMainRole(null);
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [user.email]);

  const location = useLocation();

  const handleNavLinkClick = (route) => {
    // Do something when a nav link is clicked (if needed)
  };

  return (
    <div className="sidebar">
      <NavLink to="/conference" className="back_to_conference">
        <img src={BACK_ARROW} alt="" />
      </NavLink>

      <div className="sidebar-item qr">
        <QRCode value={user.email} bgColor="#004E89" fgColor="rgba(204, 204, 204, 0.87)" className="qr_s" />
      </div>

      <nav className="sidebar-item nav">
        <ul className="nav-items">
          <li>
            <NavLink
              to="/conference/agenda"
              className="nav-link"
              activeClassName="active"
              onClick={() => handleNavLinkClick('agenda')}
            >
              <div
                className={`nav-item ${
                  location.pathname === '/conference/agenda' ||
                  location.pathname.startsWith('/conference/agenda/session_chat/')
                    ? 'active_navItem'
                    : ''
                }`}
              >
                <img
                  src={
                    location.pathname === '/conference/agenda' ||
                    location.pathname.startsWith('/conference/agenda/session_chat/')
                      ? AGENDA_ACTIVE
                      : AGENDA
                  }
                  alt=""
                  className="nav-icon"
                />
                <span className="icon-name">Agenda</span>
              </div>
            </NavLink>
          </li>

        {mainRole === 'OC' && (

          <li>
            
            <NavLink
              to="/conference/participation"
              className="nav-link"
              activeClassName="active"
              onClick={() => handleNavLinkClick('participation')}
            >
              <div
                className={`nav-item ${
                  location.pathname === '/conference/participation' ? 'active_navItem' : ''
                }`}
              >
                <img
                  src={
                    location.pathname === '/conference/participation' ? PARTICIPATION_ACTIVE : PARTICIPATION
                  }
                  alt=""
                  className="nav-icon"
                />
                <span className="icon-name">Participation</span>
              </div>
            </NavLink>
            
          </li>

        )}

        {mainRole === 'OC' && (
          <li>
            
            <NavLink
              to="/conference/community/oc_members"
              className="nav-link"
              activeClassName="active"
              onClick={() => handleNavLinkClick('community')}
            >
              <div
                className={`nav-item ${
                  location.pathname === '/conference/community/oc_members' ||
                  location.pathname === '/conference/community/speakers' ||
                  location.pathname === '/conference/community/authors' ||
                  location.pathname === '/conference/community/organizations' ||
                  location.pathname === '/conference/community/attendees' ||
                  location.pathname === '/conference/Community/attendees/addattendee'
                    ? 'active_navItem'
                    : ''
                }`}
              >
                <img
                  src={
                    location.pathname === '/conference/community/oc_members' ||
                    location.pathname === '/conference/community/speakers' ||
                    location.pathname === '/conference/community/authors' ||
                    location.pathname === '/conference/community/organizations' ||
                    location.pathname === '/conference/community/attendees' ||
                    location.pathname === '/conference/Community/attendees/addattendee'
                      ? COMMUNITY_ACTIVE
                      : COMMUNITY
                  }
                  alt=""
                  className="nav-icon"
                />
                <span className="icon-name">Community</span>
              </div>
            </NavLink>
            
          </li>
        )}

        {(mainRole === 'OC' || mainRole === 'ORGANIZATION') && (
          <li>
            
            <NavLink
              to="/conference/events"
              className="nav-link"
              activeClassName="active"
              onClick={() => handleNavLinkClick('events')}
            >
              <div
                className={`nav-item ${
                  location.pathname === '/conference/events' ? 'active_navItem' : ''
                }`}
              >
                <img
                  src={location.pathname === '/conference/events' ? EVENTS_ACTIVE : EVENTS}
                  alt=""
                  className="nav-icon"
                />
                <span className="icon-name">events</span>
              </div>
            </NavLink>
            
          </li>
        )}

        {mainRole === 'OC' && (
          <li>
            
            <NavLink
              to="/conference/notifications/all_notifications"
              className="nav-link"
              activeClassName="active"
              onClick={() => handleNavLinkClick('notifications')}
            >
              <div
                className={`nav-item ${
                  location.pathname === '/conference/notifications/all_notifications' ||
                  location.pathname === '/conference/notifications/general_notifications' ||
                  location.pathname === '/conference/notifications/event_notices' ||
                  location.pathname === '/conference/notifications/connection_requests'
                    ? 'active_navItem'
                    : ''
                }`}
              >
                <img
                  src={
                    location.pathname === '/conference/notifications/all_notifications' ||
                    location.pathname === '/conference/notifications/general_notifications' ||
                    location.pathname === '/conference/notifications/event_notices' ||
                    location.pathname === '/conference/notifications/connection_requests'
                      ? NOTIFICATIONS_ACTIVE
                      : NOTIFICATIONS
                  }
                  alt=""
                  className="nav-icon"
                />
                <span className="icon-name">Announcements</span>
              </div>
            </NavLink>
           
          </li>


        )}
        </ul>
      </nav>

      <div className="sidebar-item social-icons-2">
        <AiFillFacebook className="social-icon" />
        <AiFillLinkedin className="social-icon" />
        <AiFillTwitterSquare className="social-icon" />
      </div>

    </div>
  );
};

export default SideNav;