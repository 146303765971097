import React from "react";

import Image from "../components/public_profiles/ProfileImage";
import Details from "../components/public_profiles/ProfileDetails";

import PageLayout from '../layout/PageLayout2';


const Profiles = () => {
  const WrappedComponent = PageLayout; 
  return (
    <div>
      <WrappedComponent>
        <div>
      <div className="page-container">
        <div className="content">
          <div className="community">
            <h3>Community</h3>
          </div>
          <Image></Image>
          <Details></Details>         
        </div>
      </div>
    </div>
    </WrappedComponent>
    </div>
  );
};

export default Profiles;
