import { React, useState } from 'react';
import '../../styles/Navbar.css';

import { NavLink, useLocation } from 'react-router-dom';

// Import icons
import {
  AiFillFacebook,
  AiFillLinkedin,
  AiFillTwitterSquare
} from 'react-icons/ai';
import QR from '../../assets/images/navbar/QR.svg';
import CONFERENCE from '../../assets/images/navbar/conference.svg';
import CONTACTS from '../../assets/images/navbar/contacts.svg';
import HELP from '../../assets/images/navbar/help.svg';
import CONFERENCE_ACTIVE from '../../assets/images/navbar/conferenceFilled.svg';
import CONTACTS_ACTIVE from '../../assets/images/navbar/contactsFIlled.svg'
import HELP_ACTIVE from '../../assets/images/navbar/helpFilled.svg'


const SideNav = () => {
  const location = useLocation();
  const [activeNavItem, setActiveNavItem] = useState('');

  const handleNavLinkClick = (navItem) => {
    setActiveNavItem(navItem);
  };

  return (

    /************************* Side Bar **************************/

    <div className='sidebar'>

      {/************************* Side Bar - QR **************************/}

      <div className='sidebar-item qr_s'>
        {/* <img src="" alt='QR' /> */}
      </div>

      {/************************* Side Bar - Navigation Items **************************/}

      <nav className='sidebar-item nav'>
        <ul className='nav-items'>

          {/************************* Side Bar - Conference **************************/}

          <li>
            <NavLink to='/conference'>
              <div className={`nav-item ${
                  location.pathname === '/conference' ? 'active_navItem' : ''
                  }`}
                  onClick={() => handleNavLinkClick('conference')}>
                <img
                  src={
                    location.pathname === '/conference'
                      ? CONFERENCE_ACTIVE
                      : CONFERENCE
                  }
                  alt=''
                  className='nav-icon'
                />
                <span className='icon-name'>Conferences</span>
              </div>
            </NavLink>
          </li>

          {/************************* Side Bar - All-Contacts **************************/}

          <li>
            {/* <NavLink to='/all_contacts'>
              <div className={`nav-item ${
                  location.pathname === '/all_contacts' ? 'active_navItem' : ''
                  }`}
                  onClick={() => handleNavLinkClick('all_contacts')}>
                <img
                  src={
                    location.pathname === '/all_contacts'
                      ? CONTACTS_ACTIVE
                      : CONTACTS
                  }
                  alt=''
                  className='nav-icon'
                />
                <span className='icon-name'>Contacts</span>
              </div>
            </NavLink> */}
          </li>

          {/* <li>
            <hr className='middle-line'/>
          </li> */}

          {/************************* Side Bar - Help **************************/}

          {/* <li>
            <NavLink to='/help'>
              <div className={`nav-item ${
                    location.pathname === '/help' ? 'active_navItem' : ''
                    }`}
                    onClick={() => handleNavLinkClick('help')}>
                  <img
                    src={
                      location.pathname === '/help'
                        ? HELP_ACTIVE
                        : HELP
                    }
                    alt=''
                    className='nav-icon'
                  />
                  <span className='icon-name'>Help</span>
              </div>
            </NavLink>
          </li> */}
        </ul>
      </nav>

      {/************************* Side Bar - Footer **************************/}

      {/* <div className='sidebar-item social-icons-2'>
        <AiFillFacebook className='social-icon' />
        <AiFillLinkedin className='social-icon' />
        <AiFillTwitterSquare className='social-icon' />
      </div> */}
    </div>
  );
};

export default SideNav;
