import { React, useEffect, useState } from "react";
import "../styles/ForgetPassword.css";

import { useNavigate } from "react-router-dom";
// Import features

// Import icons
import {
  AiFillLinkedin,
  AiFillFacebook,
  AiFillTwitterSquare,
} from "react-icons/ai";
import ForgetPasswordImage from "../assets/images/all-users/ForgetPasswordImg.svg";
import Email from "../assets/images/all-users/email.svg";
import Password from "../assets/images/all-users/password.svg";

// Import components
import DefaultHeader from "../components/common/DefaultHeader";
import Spinner from "../components/common/Spinner";
import { auth } from "../firebase";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

// Toastify Notifications
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ForgetPassword() {
  const navigate = useNavigate();

  // if (isLoading) {
  //     return <Spinner />;
  // }
  const [email, setEmail] = useState("");

  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      await sendPasswordResetEmail(auth, email).then(() => {
        // password reset email send
        // alert(error.message)
        toast.info("Email reset link sent", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
      setTimeout(() => {
        navigate("/login");
      }, 5000);
    } catch (error) {
      alert(error.message);
      // Display an error message
      toast.error("Email already in use", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  return (
    <div className="container">
      {/* <h1>Forget password</h1> */}
      <div className="middle-container">
        <DefaultHeader className="forget-password-container-header" />

        <div className="forget-password-container-body">
          <div className="forget-password-body-left">
            <img
              src={ForgetPasswordImage}
              alt="screen img"
              className="screen-img"
            />
          </div>

          <hr />

          <div className="forget-password-body-right">
            <h4 className="title">Forgot Password</h4>
            <hr />
            <p className="reset__details">
              Enter your registration email to reset your password
            </p>

            <form className="foreget-password--form" onSubmit={onSubmit}>
              <div className="foreget-password-form">
                <div className="foreget-password-form-items">
                  <img src={Email} alt="" />
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={email}
                    placeholder="email"
                    onChange={(e) => setEmail(e.target.value)}
                    className="form-items__input"
                    required
                  />
                </div>
              </div>

              <div className="password-body-right-bottom">
                <button type="submit">Reset Password</button>
              </div>
            </form>

            {/* <form className='foreget-password--form'>
                            <div className='foreget-password-form'>


                                <div className='foreget-password-form-items'>
                                    <img src={Password} alt="" />
                                    <input
                                        type="password"
                                        id="password"
                                        name="password" // Added name attribute
                                        // value={email}
                                        placeholder='OTP'
                                        // onChange={onChange}
                                    />
                                </div>
                                
                                
                                
                            </div>
                           

                            <div className='password-body-right-bottom'>

                                
                                <button  type='submit' >Submit</button>
                                
                            </div>

                        </form> */}
          </div>
        </div>

        <div className="social-icons-4">
          <AiFillFacebook className="social-icon" />
          <AiFillLinkedin className="social-icon" />
          <AiFillTwitterSquare className="social-icon" />
        </div>
      </div>

      {/* add toastify container -> don't remove this */}
      {/* <ToastContainer /> */}
    </div>
  );
}

export default ForgetPassword;
