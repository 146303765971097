import React, { useEffect, useState } from 'react';
import '../../styles/Notification_Card.css';
import { onSnapshot, collection, orderBy, query } from 'firebase/firestore';
import { db } from '../../firebase'

const Meassages = () => {
  const [announcements, setAnnouncements] = useState([]);

  const formatDate = (date) => {
    const day = date.getDate();
    const month = date.toLocaleString("en-US", { month: "long" });
    return `${day} of ${month}`;
  }

  const formatTime = (date) => {
    const now = new Date();
    const secondsPast = (now.getTime() - date.getTime()) / 1000;
    if (secondsPast < 60) {
      return 'just now';
    }
    if (secondsPast < 3600) {
      return parseInt(secondsPast / 60) + ' minutes ago';
    }
    if (secondsPast <= 86400) {
      return parseInt(secondsPast / 3600) + ' hours ago';
    }
    if (secondsPast > 86400) {
      return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    }
  }

  useEffect(() => {
    const announcementsCollection = collection(db, 'Announcements');
    const q = query(announcementsCollection, orderBy("time", "desc"));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const announcementList = snapshot.docs.map(doc => doc.data());
      setAnnouncements(announcementList);
    });

    // Clean up subscription on unmount
    return () => unsubscribe();
  }, []);

  return (
    <div>
      {announcements.map((announcement, index) => (
        <div className='notification_card' key={index}>
          <h3>{formatDate(announcement.time.toDate())}</h3>
          <h4>{formatTime(announcement.time.toDate())}</h4>
          <p>{announcement.content}</p>
        </div>
      ))}
    </div>
  );
};

export default Meassages;
