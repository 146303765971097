// Header for 
// -- Home Page , Login Page, Sign Up Page, Forget-Password Page, Reset-Password Page

import React from 'react'
import '../../styles/DefaultHeader.css';

import { Link } from 'react-router-dom';

import Logo from '../../assets/images/header/confolink.svg'
import HomeIcon from '../../assets/images/all-users/home-icon.svg'


function DefaultHeader() {
    return (
        <div className='container-header'>
            <img src={Logo} alt="" className='logo'/>
            <Link to="/"><img src={HomeIcon} alt="" className='home-icon'/></Link>
        </div>
    );
}

export default DefaultHeader;