import React from 'react';
import '../styles/Home.css';

import { Link } from 'react-router-dom';

// Import icons
import LOGO from '../assets/images/header/confolink.svg';
import HOME from '../assets/images/all-users/home.svg';
import { AiFillLinkedin, AiFillFacebook, AiFillTwitterSquare} from 'react-icons/ai';


function Home() {
  return (
    
    <div className='container'>
        <div className='home-container'>

            {/* **************** Home Page Header **************** */}

            <div className='container-header-home'>
                <img src={LOGO} alt="" />
                <div className='header-right'>
                <Link to="/">Home</Link>
                <Link to="/about">About Us</Link>
                <Link to="/register"><button>Sign Up</button></Link>
                </div>
            </div>

            {/* **************** Home Page Content **************** */}

            <div className='container-body-home'>
                <div className='home__body-left'>
                    <div>
                        <p>Inspiring moments</p>
                        <p>await as you dive</p>
                        <p>with us</p>
                    </div>
                    <Link to="/login"><button className='login-btn'>Log In</button></Link>
                </div>
                <img src={HOME} alt=""/>
            </div>

            {/* **************** Home Page Footer **************** */}

            <div className='social-icons-3'>
               <AiFillFacebook className='social-icon'/>
               <AiFillLinkedin className='social-icon'/>
               <AiFillTwitterSquare className='social-icon'/>
            </div>

        </div>
    </div>
  );

}

export default Home;