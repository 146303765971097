import React from 'react';
import Sidebar from '../components/common/Navbar.jsx';
import Header from '../components/common/Header.jsx';
import '../styles/PageLayout.css';

function PageLayout({children, conferenceId}) {

    console.log('PageLayout ' + conferenceId);

  return (
    <div className='__page'>
      <Sidebar conferenceId={conferenceId}/>
      <div className='pageRight'>
        <Header />
        <div className='pageContent'>
          {children}
        </div>
      </div>
    </div>
  );
}

export default PageLayout;
