import React from 'react';
import '../styles/UserProfileAttendee.css'

import AttachmentIcon from '../assets/images/all-users/AttachmentIcon.svg'
import DeleteIcon from '../assets/images/all-users/deleteIcon.svg'
import UploadIcon from '../assets/images/all-users/uploadIcon.svg'
import Avatar from '@mui/material/Avatar';

import PageLayout from '../layout/PageLayout1';

function UserProfile  ()  {

  const WrappedComponent = PageLayout; 

  return (
    <div>
      <WrappedComponent>
        <div className='UserProfile-page'>
            <div>
                <h3>Profile</h3>
            </div>

            <div className='UserProfile__content'>
                <div className='user__bio'>
                    
                    <Avatar className='user__profile-picture' />                    
                    
                    <div className='__bio'>
                        <label>Bio :</label>
                        <textarea type="text" placeholder='Add a bio'/>
                    </div>

                    <div className='social__account__links'>
                        <label>Social Accounts : </label>
                        <div className='user__detail socialAccounts'>
                            <img src={AttachmentIcon} alt="" />
                            <input type="text" placeholder='Link to social profile'/>
                        </div>

                        <div className='user__detail socialAccounts'>
                            <img src={AttachmentIcon} alt="" />
                            <input type="text" placeholder='Link to social profile'/>
                        </div>

                        <div className='user__detail socialAccounts'>
                            <img src={AttachmentIcon} alt="" />
                            <input type="text" placeholder='Link to social profile'/>
                        </div>

                        
                    </div>

                </div>

                <hr />

                <div className='user__details'>
                  <div className='__user'>
                    {/* name */}
                    <h2>Shanaka Fernando</h2>
                    {/* designation */}
                    <h6>Attendee</h6>
                  </div> 

                  <form action="" className='user__form'>
                    
                    <div className='user__name'>
                      <div className='user__detail name'>
                        <label htmlFor="">First Name : </label>
                        <input type="text" />
                      </div>
                      <div className='user__detail name'>
                        <label htmlFor="">Last Name : </label>
                        <input type="text" />
                      </div>
                    </div>

                    <div className='user__detail mobile'>
                        <label htmlFor="">Mobile No : </label>
                        <input type="tel" />
                    </div>

                    <div className='user__detail email'>
                        <label htmlFor="">Email : </label>
                        <input type="tel" />
                    </div>

                    <h4>My Work : </h4>
                    <div className='user__work'>
                        
                        <div className='user__work__cards'>
                            <div className='user__work__card'>
                                <p>Research on Gamification for Learning </p>
                                <img src={DeleteIcon} alt="" />

                            </div>
                        </div>

                        <div className='user__work__upload'>
                            <img src={UploadIcon} alt="" />
                            <p>Drag & Drop the slip here to upload <br />or</p>
                            <input type="file" hidden accept=".doc,.docx,.pdf" id="fileID" /> 
                            <button class="browse__btn">Browse</button>
                        </div>

                    </div>

                    <div className='user__profile__buttons'>
                        <button className='cancel__btn'>Cancel</button>
                        <button className='save__btn'>Save</button>
                    </div>

                    

                  </form>      

                  


                </div>

               

            </div>

        </div>
        
        

      </WrappedComponent>
    </div>
  );
};

export default UserProfile;