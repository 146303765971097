import React from "react";
import "../../styles/Notification.css";
import PageLayout from '../../layout/PageLayout2';
import "../../styles/Notification_Card.css";
import Message from "./Notification_Card";
import Request from "./Notification_request";
import NotificationComponent from "./Create_notification";

const general_Notifications = () => {
  const WrappedComponent = PageLayout; 
  return (
    <div className="notification_content">
        <Message />

        <NotificationComponent />

    </div>
  );
};

export default general_Notifications;
