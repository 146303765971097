import React, {useEffect} from 'react';
import '../styles/Speakers.css'

import {useLocation, useNavigate} from "react-router-dom";

import SearchBox from "../components/community/SearchBox";
import ContactItem from '../components/contacts/ContactItem';
import AddContact from '../components/contacts/AddContactForm';

import PageLayout from '../layout/PageLayout2';




function ViewContacts  ()  {

  
    

  const WrappedComponent = PageLayout; 

  return (
    <div>
      {/* <WrappedComponent >
        
        <div className='speakersPage'>
            <div className='speakersPage__header'>
                <h3 className="community-header">Contacts</h3>
                <SearchBox />
            </div>

            <div className="speakersPage__content">
                <div className="speakersPage__left">
                    {contacts.length > 0 ? (
                        <div className='speakers__items'>
                            {contacts.map((contact) => (
                                <ContactItem key={contact.id} contact={contact} />
                            ))}
                        </div>
                    ) : (
                        <h3>There is no contacts</h3>
                    )}
                </div>
                <div className="speakersPage__right">
                    <AddContact />
                </div>
            </div>
        </div>
        

      </WrappedComponent> */}
    </div>
  );
};

export default ViewContacts;
