import {React, useState} from "react";
import "../styles/Notification_Card.css";

import {useLocation, useNavigate, Outlet , Link} from "react-router-dom";


import PageLayout from '../layout/PageLayout2';


const Notifications = () => {

  const location = useLocation();
  const conferenceId = location.state?.conferenceId;

  const WrappedComponent = PageLayout; 

  const [activeNavItem, setActiveNavItem] = useState('');


  const handleNavLinkClick = (topbar__item) => {
    setActiveNavItem(topbar__item);
  };

  return (
    <WrappedComponent  conferenceId={conferenceId}>
      <div className="NotificationPage">
        <div className='NotificationPage__header'>
          <h3 className="notification-header">Notifications</h3>
          
        </div>

        {/* <TopBar/> */}

        <div className='communityPage-topbar'>

          {/* <Link to="/conference/notifications/all_notifications" className={`topbar__item ${
              location.pathname === '/conference/notifications/all_notifications' ? 'active_topbar__item' : ''
              }`}
              onClick={() => handleNavLinkClick('all_notifications')}
          >
              All Notifications 
          </Link> */}
          
          
          <Link to="/conference/notifications/general_notifications" className={`topbar__item ${
              location.pathname === '/conference/notifications/general_notifications' ? 'active_topbar__item' : ''
              }`}
            onClick={() =>
              handleNavLinkClick('general_notifications'
              )}
          > 
              Announcements
          </Link>
          {/* <Link to="/conference/notifications/event_notices" className={`topbar__item ${
              location.pathname === '/conference/notifications/event_notices' ? 'active_topbar__item' : ''
              }`}
              onClick={() => handleNavLinkClick('event_notices')}

          > 
              Event Notices 
          </Link> */}
          {/* <Link to="/conference/notifications/connection_requests" className={`topbar__item ${
              location.pathname === '/conference/notifications/connection_requests' ? 'active_topbar__item' : ''
              }`}
              onClick={() => handleNavLinkClick('connection_requests')}
          > 
              Connection Requests 
          </Link> */}
          

        </div>

        <Outlet />

        


      </div>
    </WrappedComponent>
  );
};

export default Notifications;
