import React, {useEffect, useState} from 'react';

import { db } from "../../firebase";
import { collection, query, where, getDocs } from "firebase/firestore";

import AuthorItem from "./AuthorItem";
import AddAuthorForm from "./AddAuthorForm";

import '../../styles/Authors.css'

import Spinner from "../common/Spinner";

function Authors  ()  {
    const [isLoading, setIsLoading] = useState(false);
    const [authors, setAuthors] = useState([]);

    useEffect(() => {
      setIsLoading(true);
      const q = query(collection(db, "participant"), where("role", "array-contains", "AUTHOR"));
  
      getDocs(q)
        .then((querySnapshot) => {
          const authorsData = [];
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            authorsData.push({
              id: doc.id,
              firstName: data.firstName,
              lastName: data.lastName,
              email: data.email,
            });
          });
          setAuthors(authorsData);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error getting attendees: ", error);
          setIsLoading(false);
        });
    }, []);

  return (
    <div className="authorsPage__content">
        <div className="authorsPage__left">
        {isLoading ? (
            <Spinner />
        ) : authors.length > 0 ? (
            authors.map((author) => (
                <div key={author.id} className='authors__items'>
                    <AuthorItem
                        firstName={author.firstName}
                        lastName={author.lastName}
                        author={author}
                    />
                </div>
            ))
        ) : (
            <h3>There are no Authors</h3>
        )}
        </div>
        <div className="authorsPage__right">
            <AddAuthorForm />
        </div>
    </div>
  );
};

export default Authors;
