import '../../styles/AttendeeItem.css';
import Icons from '../community/Icons';
import profileImage from '../../assets/images/community/speaker.jpeg';

function AttendeeItem( { firstName, lastName } ) {

    return (
        <div className='attendeeCard-item'>
            <div className='attendeeCard-item__description'>
                <div className='attendeeCard-item-image'>
                    <img src={profileImage} />
                </div>
            
                <h2>{firstName} {lastName}</h2>
            </div>
            <div>
                <Icons />
            </div>
        </div>
    );
}

export default AttendeeItem;