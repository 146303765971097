import React from 'react';
import  Edit from '../../assets/images/community/view.svg';
import  Delete from '../../assets/images/community/delete.svg';

import '../../styles/AllContactCardIcons.css';


const AllContactCardIcons = ({ onView, onDelete }) => {
  return (
    <div className='allcontactPage__icons'>
       <span onClick={onView}><img className="allcontactpage-view" src={Edit} alt="view"></img></span>
      <span onClick={onDelete}><img className="allcontactpage-delete" src={Delete} alt="delete"></img></span>
    </div>
  );
};

export default AllContactCardIcons;
