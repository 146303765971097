import React, { useState } from "react";
import { db, Timestamp } from '../../firebase'
import '../../styles/AddEvent.css'
import { addDoc, collection, query, where, getDocs, doc, updateDoc } from "firebase/firestore";
import CLOSE from '../../assets/images/events/close.svg'

function AddEvent(props) {
    const [showForm, setShowForm] = useState(true);
    const [eventName, setEventName] = useState("");
    const [organization, setOrganization] = useState("");
    const [description, setDescription] = useState("");
    const [date, setDate] = useState("");
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [venue, setVenue] = useState("");
    const [isCompetition, setIsCompetition] = useState(false);

    if (!showForm) return null;

    const addEventToFirestore = async (eventData) => {
        try {
            // Add a new document with a generated ID to the "Event" collection
            await addDoc(collection(db, "Events"), eventData);
            window.alert("Event data saved to Firestore");

            // Reset form data
            setEventName('');
            setOrganization('');
            setDescription('');
            setDate('');
            setStartTime('');
            setEndTime('');
            setVenue('');
            setIsCompetition(false);

        } catch (error) {
            console.error("Error saving event data: ", error);
            window.alert("Error saving event data: " + error.message);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const start = new Date();
        start.setHours(startTime.split(':')[0]);
        start.setMinutes(startTime.split(':')[1]);

        const end = new Date();
        end.setHours(endTime.split(':')[0]);
        end.setMinutes(endTime.split(':')[1]);

        const eventData = {
            eventName,
            organization,
            description,
            date: Timestamp.fromDate(new Date(date)),
            startTime: Timestamp.fromDate(start),
            endTime: Timestamp.fromDate(end),
            location: venue,
            isCompetition
        };
        addEventToFirestore(eventData);
    }

    return (
        <div className='add-event--form'>
            <div className='add-event--form-header'>
                <h4>Create Event</h4>
                <button onClick={() => props.setShowForm(false)}>
                    <img src={CLOSE} alt="" />
                </button>
            </div>
            <form action="" className='event__form' onSubmit={handleSubmit}>

                <div className='event__detail '>
                    <label htmlFor="">Event Name : </label>
                    <input type="text" value={eventName} onChange={e => setEventName(e.target.value)} required />
                </div>

                <div className='event__detail '>
                    <label htmlFor="">Organization : </label>
                    <input type="text" value={organization} onChange={e => setOrganization(e.target.value)} required />
                </div>

                <div className='event__detail '>
                    <label htmlFor="">Description : </label>
                    <input type="text" value={description} onChange={e => setDescription(e.target.value)} />
                </div>

                <div className='event__detail '>
                    <label htmlFor="">Date : </label>
                    <input type="date" value={date} onChange={e => setDate(e.target.value)} required />
                </div>

                <div className='event__detail '>
                    <label htmlFor="">Starting Time : </label>
                    <input type="time" value={startTime} onChange={e => setStartTime(e.target.value)} required />
                </div>

                <div className='event__detail '>
                    <label htmlFor="">Ending Time : </label>
                    <input type="time" value={endTime} onChange={e => setEndTime(e.target.value)} required />
                </div>

                <div className='event__detail '>
                    <label htmlFor="">Venue : </label>
                    <input type="text" value={venue} onChange={e => setVenue(e.target.value)} required />
                </div>

                <div className='event__detail '>
                    <label htmlFor="">Is a competition : </label>
                    <input type="checkbox" checked={isCompetition} onChange={e => setIsCompetition(e.target.checked)} />
                </div>

                <button type="submit">Create</button>

            </form>

        </div>
    )
}

export default AddEvent
