import React from 'react';
import '../../styles/SearchBox.css'
import searchIcon from '../../assets/images/community/search.svg'

function SearchBox({ handleSearch }) {

    const handleChange = (event)=>{
        const searchTerm = event.target.value;
        handleSearch(searchTerm);
    }

    return (
        <div className="search-container">
            <input className="search"
                type="text"
                placeholder="Search Here"
                onChange={handleSearch}
            />
            
            <img className="search-icon" src={searchIcon} alt="Search"></img>
            
        </div>
    );

}

export default SearchBox;
