import React, {useState} from "react";
import '../../styles/AddParticipnatsForm.css';
import { AES } from 'crypto-js';
import { db } from '../../firebase.js'
import { addDoc, collection, query, where, getDocs, doc, updateDoc } from "firebase/firestore"; 

function AddOCMemberForm() {

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        staff: false,
        researcher: false,
        tshirtSize: '',
    });

    const secretKey = "57DAD6C5B3A9C7B18E9D2C475C3CB";

    const submitUser = async (event) => {
        event.preventDefault();

        const encryptedEmail = AES.encrypt(formData.email, secretKey).toString();

        const userData = {
            firstName: formData.firstName,
            lastName: formData.lastName,
            email: formData.email,
            role: ["OC"],
            staff: formData.staff,
            encrypted_email: encryptedEmail,
            isResearcher: formData.researcher,
            tSize: formData.tshirtSize,
            day1CheckedIn: false,
            day2CheckedIn: false,
            isBag: false,
            isConfKit: false,
            isNotebook: false,
            isTShirt: false,
            main_role: "OC",
            ticket_day_1: true,
            ticket_day_2: true,
        };

	    // Check if the email already exists and get the user data
        const existingUserData = await checkIfEmailExists(formData.email);

        if (existingUserData) {
            // Email exists, check the role
            if (Array.isArray(existingUserData.role)) {
                if (!existingUserData.role.includes("OC")) {
                    existingUserData.role.push("OC");
                    updateParticipantInFirestore(existingUserData);
                } else {
                    window.alert("Email already exists in the collection as OC Member");
                }
            } else {
                existingUserData.role = ["OC"];
                updateParticipantInFirestore(existingUserData);
            }
        } else {
            // Email does not exist, add the new user
            addParticipantToFirestore(userData);
        }

        
    };

    

    const userChange = (event) => {
        const { name, type, checked } = event.target;
        
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === "checkbox" ? checked : event.target.value,
        }));
    };
    

    const addParticipantToFirestore = async (userData) => {
        try {
            // Add a new document with a generated ID to the "participants" collection
            await addDoc(collection(db, "participant"), userData);
            window.alert("User data saved to Firestore");
    
            // Reset form data
            setFormData(prevState => ({
                ...prevState,
                firstName: '',
                lastName: '',
                email: '',
                staff: false,
                researcher: false,
                tshirtSize: '',
            }));
    
        } catch (error) {
            console.error("Error saving user data: ", error);
            window.alert("Error saving user data: " + error.message);
        }
    };

    // Function to update an existing document
    const updateParticipantInFirestore = async (userData) => {
        try {
            const docRef = doc(db, 'participant', userData.id); // Replace 'userData.id' with the actual document ID
            await updateDoc(docRef, userData);
            window.alert('User data updated in Firestore');
    
            // Reset form data
            setFormData(prevState => ({
                ...prevState,
                firstName: '',
                lastName: '',
                email: '',
                staff: false,
                researcher: false,
                tshirtSize: '',
            }));
            
        } catch (error) {
            console.error('Error updating user data: ', error);
            window.alert('Error updating user data: ' + error.message);
        }
    };
    

    const checkIfEmailExists = async (email) => {
        // Reference the "participants" collection and create a query
        const participantsCollection = collection(db, "participant");
        const q = query(participantsCollection, where("email", "==", email));

        // Execute the query and check if any documents are returned
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
            // Return the first document's data
            const user = querySnapshot.docs[0].data();
            const userId = querySnapshot.docs[0].id;
            return { id: userId, ...user };
        }
        return null;
    };

    const renderTshirtSizeDropdown = () => {
        if (formData.researcher) {
            return (
                <div className="new-user__control">
                    <label>T-shirt Size:</label>
                    <select name="tshirtSize" onChange={userChange}>
                        <option value="default">Select Size</option>
                        <option value="XXS">XXS</option>
                        <option value="XS">XS</option>
                        <option value="S">S</option>
                        <option value="M">M</option>
                        <option value="L">L</option>
                        <option value="XL">XL</option>
                        <option value="2XL">2XL</option>
                        <option value="3XL">3XL</option>
                    </select>
                </div>
            );
        }
        return null;
    };
    
    return (

        
        <form onSubmit={submitUser} id ="userFormId">
            <div className="new-user">
                
                <h4>Add OC Members</h4>
                
                <div className="new-user__controls">
                    
                    <div className="new-user__control">
                        <label>First Name: </label>
                        <input type="text"name="firstName" required value={formData.firstName} onChange={userChange} />
                    </div>
                    <div className="new-user__control">
                        <label>Last Name:</label>
                        <input type="text" name="lastName" required value={formData.lastName} onChange={userChange}/>
                    </div>
                    <div className="new-user__control">
                        <label>Email:</label>
                        <input type="email" name="email" required value={formData.email} onChange={userChange} />
                    </div>
                    
                    <div className="ocForm__bottom">
                        <div className="checkbox">
                            <label>Staff Member: </label>
                            <input type="checkbox" id="staff" name="staff" checked={formData.staff} onChange={userChange}></input>
                        </div>
                        <div className="checkbox">
                            <label>I am a researcher: </label>
                            <input type="checkbox" id="researcher" name="researcher" checked={formData.researcher} onChange={userChange}></input>
                        </div>
                    </div>
                    {renderTshirtSizeDropdown()}
                </div>
                
                <button type="submit">Add</button>
                
            </div>
        </form>
        
    );
    
}

export default AddOCMemberForm;