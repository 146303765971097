import React from "react";
import "../styles/Participation.css";
import SearchBox from "../components/community/SearchBox";
import "../styles/Pagination.css";

import { useLocation } from "react-router-dom";
import ResearcherParticipationTable from "../components/participation/Researcher_participation_table";
import PageLayout from "../layout/PageLayout2";
import ReactPaginate from "react-paginate";

const Participation = () => {
  const location = useLocation();
  const conferenceId = location.state?.conferenceId;

  // Simulated data for the table
  const data = [...Array(100).keys()].map((i) => `Item ${i + 1}`);
  const itemsPerPage = 8;

  const WrappedComponent = PageLayout;

  // Pagination state
  const [currentPage, setCurrentPage] = React.useState(0);

  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedData = data.slice(startIndex, endIndex);

  return (
    <div>
      <WrappedComponent conferenceId={conferenceId}>
        <div className="participationsPage">
          <div className="participation__table_container">
            <ResearcherParticipationTable currentPage={currentPage} />
        </div>
          <div className="pagination-container">
            {displayedData.length > 0 && (
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                pageCount={Math.ceil(data.length / itemsPerPage)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            )}
          </div>
        </div>
      </WrappedComponent>
    </div>
  );
};

export default Participation;
