import { React, useEffect} from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PrivateRoutes from './PrivateRoutes'; 
import { Navigate } from 'react-router-dom';

import Home from './pages/Home';
import Login from './pages/Login';
import Register from './pages/Register';
import Conference from './pages/Conference';
import Agenda from './pages/Agenda';
import Session from './components/agenda/ViewSession'
import Community from './pages/Community';


import OCMembers from "./components/community/OCMembers";
import Speakers from "./components/community/Speakers";
import Authors from "./components/community/Authors";
import Organizations from "./components/community/Organizations";
import Attendees from "./components/community/Attendees";

import AddAttendees from './components/community/AddAttendee';
import ForgetPassword from "./pages/ForgetPassword"
import ResetPassword from "./pages/ResetPassword"
import UserProfile from "./pages/UserProfile"
import UserProfileAttendee from "./pages/UserProfileAttendee"

import Notifications from "./pages/Notification";
import Connection_requests from "./components/notifications/Connection_requests";
import Event_notices from "./components/notifications/Event_notices";
import General_notifications from "./components/notifications/General_notifications";
import All_notifications from "./components/notifications/All_notifications";
import ViewContacts from './pages/ViewContact';
import Profiles from './pages/profile';
import Analytics from './pages/Analytics';

import QA from './pages/Q&A';

import AllContacts from './pages/AllContact';
import Participation from './pages/participation';

import Events from './pages/Events';





// import Layout from './pages/Page';

import './App.css';

function App() {

    // 

   


    return (
        <div>
            <Router>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="login" element={<Login />} />
                    <Route path="register" element={<Register />} />
                    <Route path="forget_password" element={<ForgetPassword />} />
                    <Route path="reset-password" element={<ResetPassword />} />

                    {/* Use PrivateRoute to wrap the protected routes */}
                    
                    <Route element={<PrivateRoutes/>}>
                        <Route path="conference/*">
                            <Route index element={<Conference />} /> 
                            <Route path="user_profile" element={<UserProfile />} />
                            <Route path="agenda" element={<Agenda />} >
                                <Route path="session_chat/:sessionID" element={<Session />} />

                            </Route>
                            
                            

                            <Route path='community/' element={<Community />}>
                                <Route path="oc_members" element={<OCMembers />} />
                                <Route path="speakers" element={<Speakers />} />
                                <Route path="authors" element={<Authors />} />
                                <Route path="organizations" element={<Organizations />} />
                                <Route path="attendees" element={<Attendees />} >
                                    <Route path='addattendee' element={<AddAttendees/>} /> 
                                </Route>
                            </Route>
                            <Route path="events" element={<Events />} />
                            <Route path="profiles" element={<Profiles />} />
                            
                            <Route path="notifications/*" element={<Notifications/>} >
                                <Route path="all_notifications" element={<All_notifications/>} />
                                <Route path="connection_requests" element={<Connection_requests/>} />
                                <Route path="event_notices" element={<Event_notices/>} />
                                <Route path="general_notifications" element={<General_notifications/>} />
                            </Route>
                            <Route path="q&a" element={<QA />} />
                            <Route path="contacts" element={<ViewContacts />} />
                            <Route path="participation" element={<Participation />} />
                            <Route path="analytics" element={<Analytics />} />
                        </Route>
                        
                        <Route path="all_contacts" element={<AllContacts />} />
                        <Route path="user_profile_attendee" element={<UserProfileAttendee />} />

                    </Route>

                </Routes>
            </Router>
        </div>
    );
}

export default App;
