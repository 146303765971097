import { initializeApp } from "firebase/app";
import { getFirestore, Timestamp } from "firebase/firestore";
import { getAuth, setPersistence, browserSessionPersistence } from "firebase/auth";
import { getStorage } from "firebase/storage";


const firebaseConfig = {
    // apiKey: process.env.apiKey,
    // authDomain: process.env.authDomain,
    // projectId: process.env.projectId,
    // storageBucket: process.env.storageBucket,
    // messagingSenderId: process.env.messagingSenderId,
    // appId: process.env.appId,
    // measurementId: process.env.measurementId
    apiKey: "AIzaSyBPkO18ZzVBm9Fi_7b_NGimK6d_z1GDUJE",
    authDomain: "confolink-6a5ec.firebaseapp.com",
    projectId: "confolink-6a5ec",
    storageBucket: "confolink-6a5ec.appspot.com",
    messagingSenderId: "573330651304",
    appId: "1:573330651304:web:c035bde01d4d4bd84eaa9d",
    measurementId: "G-L35VBR3HCH"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


  
export const auth = getAuth(app);
export const db = getFirestore(app);
export { Timestamp };
export const storage = getStorage(app);

