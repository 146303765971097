import React from "react";
import "../../styles/ProfileWork.css";

const Work = () => {
  return (
    <div className="public_profile_work">
      <div className="public_profile_cards-container">
        <div className="public_profile_work_card">
          <h3>Reasearch on Gamification for learning</h3>
        </div>
        <div className="public_profile_work_card">
          <h3>Research on Human Compter Interactions</h3>
        </div>
        <div className="public_profile_work_card">
          <h3>Research on Blockchain and DLT</h3>
        </div>
        {/* Add more cards here if needed */}
      </div>
    </div>
  );
};

export default Work;
