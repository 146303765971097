import React, { useState } from 'react';
import '../../styles/QACardItem.css';
import profile from "../../assets/images/community/profile.jpg"
import answerProfileImage from "../../assets/images/community/profile2.jpg";





function QACard({ question, profileImage, name, timestamp }) {
  const [showReply, setShowReply] = useState(false);
  const [answer, setAnswer] = useState('');
  const [submittedAnswers, setSubmittedAnswers] = useState([]);

  const handleReplyClick = () => {
    setShowReply(true);
  };

  const handleAnswerChange = (event) => {
    setAnswer(event.target.value);
  };

  const handleSendReply = () => {
    if (answer.trim() !== '') {
      const newAnswer = {
        name: 'You', // Assuming the answer is from the logged-in user
        profileImage: answerProfileImage,
        answer,
        timestamp: new Date(),
      };
      setSubmittedAnswers([...submittedAnswers, newAnswer]);
      setAnswer('');
      setShowReply(false);
    }
  };

  function formatTimestamp(timestamp) {
    const now = new Date();
    const diffInSeconds = Math.floor((now - timestamp) / 1000);

    if (diffInSeconds < 60) {
      return `${diffInSeconds} seconds ago`;
    } else if (diffInSeconds < 3600) {
      const minutes = Math.floor(diffInSeconds / 60);
      return `${minutes} minutes ago`;
    } else if (diffInSeconds < 86400) {
      const hours = Math.floor(diffInSeconds / 3600);
      return `${hours} hours ago`;
    } else {
      const days = Math.floor(diffInSeconds / 86400);
      return `${days} days ago`;
    }
  }

  function formatFullTimestamp(timestamp) {
    const options = { year: 'numeric', month: 'long', day: 'numeric'};
    return new Date(timestamp).toLocaleDateString(undefined, options);
  }

  return (
    <div className="qacard">
      <div className="card-header">
        <img src={profile} alt="Profile" className="profile-image" />
        <div className="profile-info">
          <p className="name">{name}</p>

          <p className='timestamp2'>{formatFullTimestamp(timestamp)}</p>
          
        </div>
      </div>
      
      <p className="question">{question}</p>
      {!showReply && (
        <button className="reply-button" onClick={handleReplyClick}>
          Reply
        </button>
      )}
      {showReply && (
        <div className="reply-section">
          <input type='text'
            value={answer}
            onChange={handleAnswerChange}
            className="reply-textarea"
            placeholder="Type your reply here"
          />
          <button className="send-button" onClick={handleSendReply}>
            Send
          </button>
        </div>
       
      )}
      {submittedAnswers.map((answer, index) => (
        <div key={index} className="submitted-answer">
          <div className="answer-header">
            <img src={answer.profileImage} alt="Profile" className="profile-image" />
            <div className='ansInfo'>
            <p className="AnsName">{answer.name}</p>
            <p className='timestamp2'>{formatFullTimestamp(answer.timestamp)}</p>
            <p className='timestamp'>{formatTimestamp(answer.timestamp)}</p>
          </div>
        </div>
          <p className="answer">{answer.answer}</p>
        </div>
      ))}
    </div>
  );
}

export default QACard;
