import React from 'react';
import { NavLink, useParams } from 'react-router-dom';
import SessionDetails from './SessionDetails';
import SessionChat from './SessionChat';
import BACK_ARROW from '../../assets/images/navbar/arrow_back_black.svg'

function ViewSession() {
  // Access the sessionID from the route parameters
  const { sessionID } = useParams();

  return (
    <div className="session-page">
      <div className='session-page-header'>
        <NavLink 
          to='/conference/agenda'
          className='back_to_agenda'
        >
          <img src={BACK_ARROW} alt="" />
        </NavLink>
        <h4>Session Chat</h4>
      </div>
      <SessionChat sessionID={sessionID} /> 
      {/* <SessionDetails sessionID={sessionID} />  */}
    </div>
  );
}

export default ViewSession;
