import React, { useState } from "react";
import "../styles/AllContacts.css";
import "../styles/Pagination.css";

import profileImage from "../assets/images/community/profile.jpg";

import SearchBox from "../components/community/SearchBox";
import AllContactItem from "../components/AllContact/AllContactItem";

import PageLayout from "../layout/PageLayout1";
import ReactPaginate from "react-paginate";

const Search = () => {
  const [searchText, setSearchText] = useState("");

  const handleSearch = (text) => {
    setSearchText(text);
  };

  return <SearchBox handleSearch={handleSearch}></SearchBox>;
};

function AllContacts() {
  // Simulated data for the table
  const data = [...Array(100).keys()].map((i) => `Item ${i + 1}`);
  const itemsPerPage = 10;

  // Pagination state
  const [currentPage, setCurrentPage] = React.useState(0);

  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedData = data.slice(startIndex, endIndex);
  //ToDo: get all contacts from backend
  const contacts = [
    {
      id: "c1",
      imageSrc: profileImage,
      title: "Avishka Perera",
    },
    { id: "c2", imageSrc: profileImage, title: "Nadeesha Bandara" },
    {
      id: "c3",
      title: "Isini De Silva",
      imageSrc: profileImage,
    },

    {
      id: "c4",
      title: "Nayomi Karuratne",
      imageSrc: profileImage,
    },
    {
      id: "c5",
      imageSrc: profileImage,
      title: "Shanaka Fernando",
    },
    { id: "c6", imageSrc: profileImage, title: "Nadeesha Bandara" },
    {
      id: "c7",
      title: "Nirupama Dissanayaka",
      imageSrc: profileImage,
    },

    {
      id: "c8",
      title: "Charith Sathsara",
      imageSrc: profileImage,
    },
  ];

  const WrappedComponent = PageLayout;

  return (
    <div>
      <WrappedComponent>
        <div className="allcontactPage">
          <div className="allcontactPage__header">
            <h3 className="community-header">All Contacts</h3>
            <SearchBox />
          </div>

          <div className="allcontactPage__content">
            {contacts.map((contact) => (
              <div key={contact.id} className="allcontact__card">
                <AllContactItem contact={contact} />
              </div>
            ))}
          </div>
          <div className="pagination-container">
            {displayedData.length > 0 && (
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                pageCount={Math.ceil(data.length / itemsPerPage)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            )}
          </div>
        </div>
      </WrappedComponent>
    </div>
  );
}

export default AllContacts;
