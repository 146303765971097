import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/all-users/conforencelogo1.svg";
import location from "../../assets/images/all-users/location.svg";
import calendar from "../../assets/images/all-users/calendar.svg";
import attendee from "../../assets/images/all-users/attendee.svg";

import "../../styles/ConferenceItem.css";
//import ConferencePopup from "./ConferencePopup";

// Toastify erros
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ConferenceItem({ conference, mainRole }) {
  // console.log("ConferenceItem " + conference.id);
  //  const [isPopupOpen, setIsPopupOpen] = useState(false);

  //   function openPopup() {
  //     setIsPopupOpen(true);
  //   }

  //   function closePopup() {
  //     setIsPopupOpen(false);
  //   }

  const handleClick = (event) => {
    if (mainRole === "ATTENDEE") {
      event.preventDefault();
      // alert("You are not allowed to access this conference.");
      toast.error("You are not allowed to access this conference.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      // Navigate to the conference component
    }
  };

  return (
    <Link to="/conference/agenda" state={{ conferenceId: conference.id }}>
      <div className="conferences-body" onClick={handleClick}>
        <img src={logo} alt="" />
        <div className="conference-info" /*onClick={openPopup}*/>
          <h5>{conference.name}</h5>
          <div className="conference-info--detail">
            <img src={location} alt="" />
            <p>{conference.location}</p>
          </div>
          <div className="conference-info--detail">
            <img src={calendar} alt="" />
            <p>2023.11.08 to 2023.11.09</p>
          </div>
          <div className="conference-info--detail">
            <img src={attendee} alt="" />
            <p>Attendee</p>
          </div>
        </div>
        {/* {isPopupOpen && (
        <div className="Conference-popup-container">
          <ConferencePopup onClose={closePopup} />
        </div>
      )} */}

        {/* add toastify container -> don't remove this */}
        <ToastContainer />
      </div>
    </Link>
  );
}

export default ConferenceItem;
