import React, { useState } from 'react';
import Edit from '../../assets/images/community/edit.svg';
import Delete from '../../assets/images/community/delete.svg';
import DeleteConfirmationPopup from '../common/DeletePopup';

import '../../styles/Icons.css';

const Icons = ({ onEdit, handleDeleteClick }) => {
  const [isDeletePopupOpen, setDeletePopupOpen] = useState(false);

  const handleCancelDelete = () => {
    setDeletePopupOpen(false);
  };

  const handleConfirmDelete = () => {
    setDeletePopupOpen(false);
  };

  return (
    <div className='ocPage__icons'>
      <span onClick={onEdit}><img className="edit" src={Edit} alt="edit"></img></span>
      <span onClick={handleDeleteClick}><img className="delete" src={Delete} alt="delete"></img></span>

      {isDeletePopupOpen && (
        <DeleteConfirmationPopup
          onCancel={handleCancelDelete}
          onConfirm={handleConfirmDelete}
        />
      )
      }
    </div>
  );
};

export default Icons;
