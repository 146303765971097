import React, { useState } from "react";
import "../styles/LoginRegister.css";
import {
  getAuth,
  createUserWithEmailAndPassword,
  updateProfile,
  sendEmailVerification,
} from "firebase/auth";
import { getFirestore, collection, doc, setDoc } from "firebase/firestore";

import { Link, Navigate, useNavigate } from "react-router-dom";

// Import icons
import {
  AiFillLinkedin,
  AiFillFacebook,
  AiFillTwitterSquare,
} from "react-icons/ai";
import { faCheck, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import LoginImg from "../assets/images/all-users/SignupImg.svg";
import Email from "../assets/images/all-users/email.svg";
import Password from "../assets/images/all-users/password.svg";
import Pen from "../assets/images/all-users/pen.svg";
import Telephone from "../assets/images/all-users/telephone.svg";

// Import components
import DefaultHeader from "../components/common/DefaultHeader";
import Spinner from "../components/common/Spinner";

// Toastify Notifications
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Register() {
  const navigate = useNavigate();

  //* **************** Regex Expressions for validations **************** *
  const USERNAME_REGEX = /^[A-Za-z]{1,24}$/;
  const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const MOBILE_REGEX =
    /^(?:(?:\+|00)1\s*[-]?)?(?:\(\d{3}\)|\d{3})[-]?\d{3}[-]?\d{4}$/;
  const PASSWORD_REGIX =
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

  //* **************** State for the form * ****************
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    mobile: "",
    password: "",
    re_password: "",
  });

  const [validFormData, setValidFormData] = useState({
    firstname: false,
    lastname: false,
    email: false,
    mobile: false,
    password: false,
    re_password: false,
  });

  const [formDataFocus, setFormDataFocus] = useState({
    firstname: false,
    lastname: false,
    email: false,
    mobile: false,
    password: false,
    re_password: false,
  });

  //* **************** De-structure the fields from that * ****************
  const { firstname, lastname, email, mobile, password, re_password } =
    formData;

  const onChange = (e) => {
    const { name, value } = e.target;

    // * **************** Update the state * ****************
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    // * **************** Validate in real-time * ****************
    if (name === "firstname") {
      setValidFormData((prevValidFormData) => ({
        ...prevValidFormData,
        firstname: USERNAME_REGEX.test(value),
      }));
    }

    if (name === "lastname") {
      setValidFormData((prevValidFormData) => ({
        ...prevValidFormData,
        lastname: USERNAME_REGEX.test(value),
      }));
    }

    if (name === "email") {
      setValidFormData((prevValidFormData) => ({
        ...prevValidFormData,
        email: EMAIL_REGEX.test(value),
      }));
    }

    if (name === "mobile") {
      setValidFormData((prevValidFormData) => ({
        ...prevValidFormData,
        mobile: MOBILE_REGEX.test(value),
      }));
    }

    if (name === "password") {
      setValidFormData((prevValidFormData) => ({
        ...prevValidFormData,
        password: PASSWORD_REGIX.test(value),
      }));
    }

    if (name === "re_password") {
      setValidFormData((prevValidFormData) => ({
        ...prevValidFormData,
        re_password: value === formData.password,
      }));
    }
  };

  // * **************** Handle focus * ****************
  const onFocus = (e) => {
    const { name } = e.target;

    setFormDataFocus((prevState) => ({
      ...prevState,
      [name]: true,
    }));
  };

  // * **************** Handle blur * ****************
  const onBlur = (e) => {
    const { name } = e.target;

    setFormDataFocus((prevState) => ({
      ...prevState,
      [name]: false,
    }));
  };

  // * **************** New state for tooltip visibility * ****************
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  // * **************** Mouse enter and leave events * ****************
  const onMouseEnter = () => {
    setIsTooltipVisible(true);
  };

  const onMouseLeave = () => {
    setIsTooltipVisible(false);
  };

  const auth = getAuth();
  const db = getFirestore();

  const onSubmit = async (e) => {
    e.preventDefault();

    const { email, password, firstname, lastname, mobile } = formData;

    try {
      // Create a new user account with email and password
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      // Send email verification to the user
      await sendEmailVerification(user);

      // Create a database collection to store the user's data
      await setDoc(doc(db, "Users", user.uid), {
        firstName: firstname,
        lastName: lastname,
        contactNumber: mobile,
        email: user.email,
      });

      // Display a success message
      toast.info("Email verification link sent", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      // Navigate to login page after 3 seconds
      setTimeout(() => {
        navigate("/login");
      }, 5000);
    } catch (error) {
      console.error(error);

      // Display an error message
      toast.error("Email already in use", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  // * **************** New state for password visibility * ****************
  const [passwordVisible, setPasswordVisible] = useState(false);

  // if(isLoading){
  //   return <Spinner />
  // }

  // ************************************************************
  

  return (
    <div className="container">
      <div className="middle-container">
        <DefaultHeader />

        <div className="container-body">
          <div className="body-left">
            <img src={LoginImg} alt="screen img" className="screen-img" />
          </div>

          <hr />

          <div className="body-right">
            <p className="title">Sign Up</p>

            {/* * **************** Sign Up Form * **************** */}

            <form onSubmit={onSubmit}>
              <div className="form">
                <div className="form-items__fullname">
                  {/* * **************** Sign Up Form - First Name * **************** */}

                  <div className="form-items">
                    <div className="form-items-cat1">
                      <img src={Pen} alt="" />
                      <input
                        type="text"
                        id="firstname"
                        name="firstname"
                        placeholder="First Name"
                        onChange={onChange}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        value={formData.firstname}
                        className="form-items__input"
                        required
                      />
                      <label htmlFor="firstname" className="form-items__label">
                        First Name
                      </label>
                    </div>
                    <div>
                      <FontAwesomeIcon
                        icon={faCheck}
                        className={
                          validFormData.firstname && formData.firstname
                            ? "valid"
                            : "hide"
                        }
                      />
                      <div
                        className={`invalid-errormessage ${
                          !validFormData.firstname && formData.firstname
                            ? "active"
                            : ""
                        }`}
                      >
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          className={
                            !validFormData.firstname && formData.firstname
                              ? "active"
                              : "hide"
                          }
                          onMouseEnter={onMouseEnter}
                          onMouseLeave={onMouseLeave}
                        />
                        <p
                          className={`instructions ${
                            !validFormData.firstname && formData.firstname
                              ? "active"
                              : "offscreen"
                          }`}
                        >
                          Please enter a valid first name (letters only)
                        </p>
                      </div>
                    </div>
                  </div>

                  {/* * **************** Sign Up Form - Last Name * **************** */}

                  <div className="form-items">
                    <div className="form-items-cat1">
                      <img src={Pen} alt="" />
                      <input
                        type="text"
                        id="lastname"
                        name="lastname"
                        placeholder="Last Name"
                        onChange={onChange}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        value={formData.lastname}
                        className="form-items__input"
                        required
                      />
                      <label htmlFor="lastname" className="form-items__label">
                        Last Name
                      </label>
                    </div>
                    <div>
                      <FontAwesomeIcon
                        icon={faCheck}
                        className={
                          validFormData.lastname && formData.lastname
                            ? "valid"
                            : "hide"
                        }
                      />
                      <div
                        className={`invalid-errormessage ${
                          !validFormData.lastname && formData.lastname
                            ? "active"
                            : ""
                        }`}
                      >
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          className={
                            !validFormData.lastname && formData.lastname
                              ? "active"
                              : "hide"
                          }
                          onMouseEnter={onMouseEnter}
                          onMouseLeave={onMouseLeave}
                        />
                        <p
                          className={`instructions ${
                            !validFormData.lastname && formData.lastname
                              ? "active"
                              : "offscreen"
                          }`}
                        >
                          Please enter a valid last name (letters only)
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* * **************** Sign Up Form - Email * **************** */}

                <div className="form-items">
                  <div className="form-items-cat1">
                    <img src={Email} alt="" />
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Email"
                      onChange={onChange}
                      onFocus={onFocus}
                      onBlur={onBlur}
                      value={formData.email}
                      className="form-items__input"
                      required
                    />
                    <label htmlFor="email" className="form-items__label">
                      Email
                    </label>
                  </div>
                  <div>
                    <FontAwesomeIcon
                      icon={faCheck}
                      className={
                        validFormData.email && formData.email ? "valid" : "hide"
                      }
                    />
                    <div
                      className={`invalid-errormessage ${
                        !validFormData.email && formData.email ? "active" : ""
                      }`}
                    >
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        className={
                          !validFormData.email && formData.email
                            ? "active"
                            : "hide"
                        }
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}
                      />
                      <p
                        className={`instructions ${
                          !validFormData.email && formData.email
                            ? "active"
                            : "offscreen"
                        }`}
                      >
                        Please enter a valid email address
                      </p>
                    </div>
                  </div>
                </div>

                {/* * **************** Sign Up Form - Contact Number * **************** */}

                <div className="form-items">
                  <div className="form-items-cat1">
                    <img src={Telephone} alt="" />
                    <input
                      type="text"
                      id="mobile"
                      name="mobile"
                      placeholder="Mobile number"
                      onChange={onChange}
                      onFocus={onFocus}
                      onBlur={onBlur}
                      value={formData.mobile}
                      className="form-items__input"
                      required
                    />
                    <label htmlFor="mobile" className="form-items__label">
                      Mobile number
                    </label>
                  </div>
                  <div>
                    <FontAwesomeIcon
                      icon={faCheck}
                      className={
                        validFormData.mobile && formData.mobile
                          ? "valid"
                          : "hide"
                      }
                    />
                    <div
                      className={`invalid-errormessage ${
                        !validFormData.mobile && formData.mobile ? "active" : ""
                      }`}
                    >
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        className={
                          !validFormData.mobile && formData.mobile
                            ? "active"
                            : "hide"
                        }
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}
                      />
                      <p
                        className={`instructions ${
                          !validFormData.mobile && formData.mobile
                            ? "active"
                            : "offscreen"
                        }`}
                      >
                        Please enter a valid contact number
                      </p>
                    </div>
                  </div>
                </div>

                {/* * **************** Sign Up Form - Password * **************** */}

                <div className="form-items">
                  <div className="form-items-cat1">
                    <img src={Password} alt="" />
                    <input
                      type={passwordVisible ? "text" : "password"}
                      placeholder="Password"
                      id="password"
                      name="password"
                      onChange={onChange}
                      onFocus={onFocus}
                      onBlur={onBlur}
                      value={formData.password}
                      className="form-items__input"
                      required
                    />
                    <label htmlFor="password" className="form-items__label">
                      Password
                    </label>
                  </div>
                  <div className="password-field-bottom">
                    <button
                      type="button"
                      onClick={() => setPasswordVisible(!passwordVisible)}
                    >
                      {passwordVisible ? (
                        <FaEye className="password-eye" />
                      ) : (
                        <FaEyeSlash className="password-eye" />
                      )}
                    </button>
                    <div
                      className={`invalid-errormessage ${
                        !validFormData.password && formData.password
                          ? "active"
                          : ""
                      }`}
                    >
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        className={
                          !validFormData.password && formData.password
                            ? "active"
                            : "hide"
                        }
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}
                      />
                      <p
                        className={`instructions ${
                          !validFormData.password && formData.password
                            ? "active"
                            : "offscreen"
                        }`}
                      >
                        Password shoul contains at least 8 characters, one
                        uppercase letter, one lowercase letter, one digit, one
                        special character
                      </p>
                      <FontAwesomeIcon
                        icon={faCheck}
                        className={
                          validFormData.password && formData.password
                            ? "valid"
                            : "hide"
                        }
                      />
                    </div>
                  </div>
                </div>

                {/* * **************** Sign Up Form - Confirm Password * **************** */}

                <div className="form-items">
                  <div className="form-items-cat1">
                    <img src={Password} alt="" />
                    <input
                      type={passwordVisible ? "text" : "password"}
                      id="re_password"
                      name="re_password"
                      placeholder="Re-enter Password"
                      onChange={onChange}
                      onFocus={onFocus}
                      onBlur={onBlur}
                      value={formData.re_password}
                      className="form-items__input"
                      required
                    />
                    <label htmlFor="re_password" className="form-items__label">
                      Re-enter Password
                    </label>
                  </div>

                  <div className="password-field-bottom">
                    <button
                      type="button"
                      onClick={() => setPasswordVisible(!passwordVisible)}
                    >
                      {passwordVisible ? (
                        <FaEye className="password-eye" />
                      ) : (
                        <FaEyeSlash className="password-eye" />
                      )}
                    </button>
                    <div
                      className={`invalid-errormessage ${
                        !validFormData.re_password && formData.re_password
                          ? "active"
                          : ""
                      }`}
                    >
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        className={
                          !validFormData.re_password && formData.re_password
                            ? "active"
                            : "hide"
                        }
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}
                      />
                      <p
                        className={`instructions ${
                          !validFormData.re_password && formData.re_password
                            ? "active"
                            : "offscreen"
                        }`}
                      >
                        Password confirmation doesn't match.
                      </p>
                      <FontAwesomeIcon
                        icon={faCheck}
                        className={
                          validFormData.re_password && formData.re_password
                            ? "valid"
                            : "hide"
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* * **************** Sign Up Form - Submit Button * **************** */}

              <div className="body-right-bottom">
                <button
                  type="submit"
                  disabled={
                    !validFormData.firstname ||
                    !validFormData.lastname ||
                    !validFormData.email ||
                    !validFormData.mobile ||
                    !validFormData.password ||
                    !validFormData.re_password
                  }
                >
                  Sign Up
                </button>

                <div className="register--link">
                  <p>Existing user?</p>
                  <Link to="/login">Log In</Link>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div className="social-icons-4">
          <AiFillFacebook className="social-icon" />
          <AiFillLinkedin className="social-icon" />
          <AiFillTwitterSquare className="social-icon" />
        </div>
      </div>

      {/* add toastify container -> don't remove this */}
      <ToastContainer />
    </div>
  );
}

export default Register;
