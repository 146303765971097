import React from "react";
import { Link } from 'react-router-dom';
import "../../styles/ProfileImage.css";
import IMG from "../../assets/images/profile/OC.jpeg"
import PROFILE from "../../assets/images/profile/plus _icon.svg"

const Image = () => {
  return (
    <div>
      <div className="public_profile_details">
        <img src={IMG} alt="" srcset="" className="public_profile_picture" />
        <div className="public_profile_details-left">
          <div className="public_user-details">
            <p className="public_user_name">Avishka Perera</p>
            <p className="public_user_designation"> OC Member</p>
          </div>
        </div>
         <div className="public_profile_add_icon">
         <Link to="">
        <img src={PROFILE} alt="" srcset="" className="public_profile_add_icon" />
          </Link>
      </div> 
      </div>
    </div>
  );
};

export default Image;
